import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Api from "../api/api";
import getPlodName from "../functions/getPlodName";
import getPlodID from "../functions/getPlodID";
import CustomNoRowsOverlay from '../components/CustomNoRowsOverlay';
import { AlertService } from '../services/AlertService';
import { handleErrorResponse } from '../api/handleErrorResponse';
import useStandardEditableRowActions from "../components/useStandardEditableRowActions";
import CustomToolBarWithDropDownToolbar from "../components/CustomToolbarWithDropdown";


const Rigs: React.FC = () => {
  document.title = `Rigs - ${getPlodName()}`;
  const [dataGridError, setDataGridError] = useState<string | null>(null);
  const plodID = getPlodID();
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});

  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows: dataRows,
    dataRowModesModel: dataRowModesModel,
    setDataRowModesModel: setDataRowModesModel,
    setDataRows: setDataRows,
  });

  const fetchData = async () => {
    setDataRows([]);
    setDataGridError(null);
    setLoading(true);
    try {
      const { data } = await Api.post(`/api/Plods/ID_${plodID}/Rigs`);
      setDataRows(data);
    } catch (e) {
      setDataRows([]);
      setDataGridError("An error occured while loading Rigs, Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (plodID) fetchData();
  }, [plodID]);

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentRigName: string = await dataRows.filter((row) => row.ID === ID)[0].RigName;
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Rig "${currentRigName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(
          `/api/Plods/ID_${plodID}/Rigs/~DeleteItem`,
          JSON.stringify({ ID })
        );
        setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted Rig "${currentRigName}".`, "success");
      } catch (e: unknown) {
        handleErrorResponse(e, `Error Deleting Rig "${currentRigName}".`);
      }
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (!newRow.RigName?.trim()) {
      await AlertService.showAlert("Rig Name cannot be empty", 'criticalerror');
    }
    else {
      try {
        const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
        const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
        const { data } = await Api.post(
          `/api/Plods/ID_${plodID}/Rigs/${endpoint}`,
          rawData
        );
        const updatedRow = {
          ...newRow,
          ID: data.ID ?? newRow.ID,
          isNew: false,
        };
        setDataRows((prev) =>
          prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
        );
        await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} Rig "${newRow.RigName}".`, "success");
        return updatedRow;
      } catch (e) {
        handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} Rig "${newRow.RigName}".`);
      }
    }
    return newRow;
  };
  return (
    <Box sx={{ height: "90vh", margin: "10px", border: 1, borderColor: '#DEDEDE', borderRadius: 2 }}>
      {/*// make these into a component so can just use easily across all, or maybe as parent idk*/}
      <DataGrid
        columns={[
          {
            field: 'RigName',
            headerName: "Rig Name",
            editable: true,
            flex: 1
          },
          {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id: ID }) => {
              const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
              return isInEditMode ? [
                <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
                <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(ID)} color="inherit" />,
              ] : [
                <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(ID)} color="inherit" />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
              ];
            },
          },
        ]}
        rows={dataRows}
        rowModesModel={dataRowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        editMode="row"
        disableRowSelectionOnClick
        loading={loading}
        getRowId={(row) => row.ID} // Gets the id from database, not the local style one
        slots={{
          toolbar: () => (<CustomToolBarWithDropDownToolbar
            setDataRows={setDataRows} // where setRigs updates the rig rows
            setDataRowModesModel={setDataRowModesModel} // for setting row modes
            rowTemplate={{ RigName: "", Status: "" }} // define initial fields for rigs
            fieldName="RigName" // focus on RigName field
            itemName="Rig"
          />),

          noRowsOverlay: () => (
            <CustomNoRowsOverlay
              message={dataGridError ? dataGridError : "No Rig Data"}
              onRetry={dataGridError ? fetchData : undefined}
            />
          ),
        }}
        slotProps={{
          toolbar: {
            setDataRows,
            setDataRowModesModel
          },
        }}
      />
    </Box>
  );
};

export default Rigs;