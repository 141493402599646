import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FilterSpacing } from '../components/FilterSpacing';
import { Box, Button, FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DataGrid } from '@mui/x-data-grid';
import Api from '../api/api';
import Swal from 'sweetalert2';
import { handleErrorResponse } from '../api/apiErrorhandle';
import getPlodID from '../functions/getPlodID';
import getPlodName from '../functions/getPlodName';

const Invoicing: React.FunctionComponent = () => {
  document.title = "Invoicing" + " - " + getPlodName();
  let plod_id: string = getPlodID();


  useEffect(() => {
    const shift: any = localStorage.getItem("shiftFilter") || null; // ? WHY IS THIS HERE
    const miniCo: any = localStorage.getItem("miningCompanyFilter") || null; // ? WHY IS THIS HERE
    const contract: any = localStorage.getItem("contractFilter") || null; // ? WHY IS THIS HERE
    const dril: any = localStorage.getItem("drillingTypeFilter") || null; // ? WHY IS THIS HERE
    const rig: any = localStorage.getItem("rigFilter") || null; // ? WHY IS THIS HERE

    console.log(contract);

    setShiftFilter(shift);
    setMiningCompanyFilter(miniCo);
    setContractFilter(contract);
    setDrillingTypeFilter(dril);
    setRigFilter(rig);
  }, []);




  const [contractFilter, setContractFilter] = React.useState('SelectContract');
  const handleContractFilterChange = (event: SelectChangeEvent) => {
    setContractFilter(event.target.value as string);
    localStorage.setItem('contractFilter', event.target.value);

  };

  const [drillingTypeFilter, setDrillingTypeFilter] =
    React.useState<string>("All");
  const handleDrillingTypeFilterChange = (event: SelectChangeEvent) => {
    setDrillingTypeFilter(event.target.value as string);
    localStorage.setItem('drillingTypeFilter', event.target.value);

  };

  const [rigFilter, setRigFilter] = React.useState<string>("All");
  const handleRigFilterChange = (event: SelectChangeEvent) => {
    setRigFilter(event.target.value as string);
    localStorage.setItem('rigFilter', event.target.value);

  };

  const [shiftFilter, setShiftFilter] = React.useState("All");
  const handleShiftFilterChange = (event: SelectChangeEvent) => {
    setShiftFilter(event.target.value as string);
    localStorage.setItem('shiftFilter', event.target.value);

  };


  const [miningCompanyFilter, setMiningCompanyFilter] = React.useState('SelectMiningCompany');
  const handleMiningCompanyFilterChange = (event: SelectChangeEvent) => {
    setMiningCompanyFilter(event.target.value as string);
    localStorage.setItem('miningCompanyFilter', event.target.value);

  };

  const [rigData, setRigData] = React.useState<any>([]);
  React.useEffect(() => {
    const fetchRigData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/Rigs`)
        .then(({ data }) => {
          if (data.length == 0) {
            setRigFilter("All");
            setRigData([]);

          } else {
            // setRigFilter("All");
            setRigData(data);
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    fetchRigData();
  }, []);




  const [drillingTypeData, setDrillingTypeData] = React.useState<any>([]);
  React.useEffect(() => {
    const fetchDrillingTypeData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/DrillingTypes`)
        .then(({ data }) => {
          if (data.length == 0) {
            setDrillingTypeFilter("All");
            setDrillingTypeData([]);
          } else {
            // setDrillingTypeFilter("All");
            setDrillingTypeData(data);
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    fetchDrillingTypeData();
  }, []);


  //#region Fetching data for Mining Company Selector.
  const [miningCompanyData, setMiningCompanyData] = React.useState<any>([]);

  React.useEffect(() => {
    const fetchMiningCompanyData = async () => {
      await Api.post(
        `/api/Plods/ID_${plod_id}/MiningCompany`,
      )
        .then(({ data }) => {
          //   setMiningCompanyFilter("SelectMiningCompany");
          setMiningCompanyData(data);
        }).catch((e) => {

          handleErrorResponse(e);

        });
    };
    fetchMiningCompanyData();
  }, []);
  //#endregion


  //#region Fetching data for Contract Selector.
  const [contractData, setContractData] = React.useState<any>([]);
  React.useEffect(() => {
    const fetchContractData = async () => {
      if (miningCompanyFilter != "SelectMiningCompany") {
        await Api.post(
          `/api/Plods/ID_${plod_id}/MiningCompany/ID_${miningCompanyFilter}/Contract`,
        )
          .then(({ data }) => {
            if (data.length == 0) {
              setContractFilter("SelectContract");
              setContractData([]);
            } else {
              setContractData(data);
            }

          }).catch((e) => {
            handleErrorResponse(e);

          });
      }
      else {
        setContractData([]);
        //  setContractFilter("SelectContract");
      }
    };
    fetchContractData();
  }, [miningCompanyFilter]);
  //#endregion

  const [startDateValue, setStartDateValue] = React.useState<Dayjs | null>(dayjs());
  const [endDateValue, setEndDateValue] = React.useState<Dayjs | null>(dayjs());

  const [invoicesRows, setInvoicesRows] = React.useState<any>([]);

  return (
    <>

      <div className="my-4">
        <FormHelperText style={{ marginLeft: 25 }}>Filters</FormHelperText>
        <hr style={{ marginLeft: 25, marginRight: 25, marginBottom: 25 }} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div
            style={{
              display: "flex",
              marginLeft: 25,
              marginRight: 25,
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            <FilterSpacing>
              <FormHelperText>Mining Company</FormHelperText>
              <Select
                id="miningCompanyFilter"
                value={miningCompanyFilter}
                onChange={handleMiningCompanyFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={'SelectMiningCompany'} key={'SelectMiningCompany'}>Select Mining Company...</MenuItem>
                {
                  miningCompanyData.map((data: any): JSX.Element => {
                    return (
                      <MenuItem value={data["ID_EA_MiningCompany"]} key={data["ID_EA_MiningCompany"]}>
                        {data["MiningCompanyName"]}
                      </MenuItem>
                    );
                  }
                  )}
              </Select>
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>Contract #</FormHelperText>
              <Select
                id="contractFilter"
                value={contractFilter}
                onChange={handleContractFilterChange}
                style={{ width: 259 }}
                disabled={miningCompanyFilter == "SelectMiningCompany"}
              >
                <MenuItem value={'SelectContract'} key={'SelectContract'}>Select Contract...</MenuItem>
                {
                  contractData.map((data: any): JSX.Element => {
                    return (
                      <MenuItem value={data["ID_EC_Contract"]} key={data["ID_EC_Contract"]}>
                        {data["ContractName"]}
                      </MenuItem>
                    );
                  }
                  )}
              </Select>
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>Drilling Program</FormHelperText>
              <Select
                id="DrillingTypeFilter"
                value={drillingTypeFilter}
                onChange={handleDrillingTypeFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {drillingTypeData.map((data: any): JSX.Element => {
                  return (
                    <MenuItem
                      value={data["ID_EA_DrillingType"]}
                      key={data["ID_EA_DrillingType"]}
                    >
                      {data["DrillingType"] + " - " + data["DrillingTypeCode"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FilterSpacing>


            <FilterSpacing>
              <FormHelperText>Start Date</FormHelperText>
              <DatePicker
                value={startDateValue}
                maxDate={endDateValue}
                onChange={(newValue) => {
                  setStartDateValue(newValue);
                  localStorage.setItem('startDate', JSON.stringify(newValue));
                }} format='DD/MM/YYYY'
              />
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>End Date</FormHelperText>
              <DatePicker
                value={endDateValue}
                minDate={startDateValue}
                maxDate={dayjs()}
                onChange={(newValue) => {
                  setEndDateValue(newValue);
                  localStorage.setItem('endDate', JSON.stringify(newValue));
                }}
                format='DD/MM/YYYY'
              />
            </FilterSpacing>


            <FilterSpacing>
              <FormHelperText>Rig</FormHelperText>
              <Select
                id="rigFilter"
                value={rigFilter}
                onChange={handleRigFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {rigData.map((data: any): JSX.Element => {
                  return (
                    <MenuItem value={data["ID_EA_Rig"]} key={data["ID_EA_Rig"]}>
                      {data["RigName"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>Shift</FormHelperText>
              <Select
                id="shiftFilter"
                value={shiftFilter}
                onChange={handleShiftFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"All"} key={"All"}>
                  All
                </MenuItem>
                <MenuItem value={"Day"} key={"Day"}>
                  Day
                </MenuItem>
                <MenuItem value={"Night"} key={"Night"}>
                  Night
                </MenuItem>
              </Select>
            </FilterSpacing>




          </div>

          <FormHelperText style={{ marginLeft: 25, marginTop: 5 }}>
            Generate Invoice      </FormHelperText>
          <hr
            style={{
              marginLeft: 25,
              marginRight: 25,
              marginBottom: 25,
              marginTop: 5,
            }}
          />
          <div
            style={{
              display: "flex",
              marginLeft: 25,
              marginRight: 25,
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            <Button
              variant="contained"

            >
              Generate Invoice
            </Button>
          </div>


          <FormHelperText style={{ marginLeft: 25, marginTop: 5 }}>
            Invoices      </FormHelperText>
          <hr
            style={{
              marginLeft: 25,
              marginRight: 25,
              marginBottom: 25,
              marginTop: 5,
            }}
          />

          <Box
            sx={{
              height: 500,
              width: '100%',
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}
          >
            <DataGrid sx={{ m: 3 }}
              columns={[
                {
                  field: 'Invoice Number',
                  headerName: "Invoice Number",
                  flex: 1,
                },
                {
                  field: 'Invoice Date',
                  headerName: "Invoice Date",
                  flex: 1,
                },
                {
                  field: 'Contract Number',
                  headerName: "Contract Number",
                  flex: 1,
                },
                {
                  field: 'Customer Name',
                  headerName: "Customer Name",
                  flex: 1,
                },
                {
                  field: 'From date',
                  headerName: "From Date",
                  flex: 1,
                },
                {
                  field: 'To date',
                  headerName: "To Date",
                  flex: 1,
                },
                {
                  field: 'Rig Number',
                  headerName: "Rig Number",
                  flex: 1,
                },
                {
                  field: 'Status',
                  headerName: "Status",
                  flex: 1,
                },
                {
                  field: 'Invoice Amount',
                  headerName: "Invoice Amount",
                  flex: 1,
                },
                {
                  field: 'Due Date',
                  headerName: "Due Date",
                  flex: 1,
                },
                {
                  field: 'Finalized',
                  headerName: "Finalized",
                  flex: 1,
                }
              ]}
              rows={invoicesRows}
              editMode='row'
            />
          </Box>
        </LocalizationProvider>
      </div >



    </>
  );
};

export default Invoicing;
