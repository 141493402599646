// getActionsForRow.tsx
import React from 'react';
import { GridActionsCellItem, GridRowId, GridRowModes } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

type RowModesModel = Record<GridRowId, { mode: GridRowModes; ignoreModifications?: boolean }>;

type Handlers = {
    handleEditClick: (id: GridRowId) => () => void;
    handleSaveClick: (id: GridRowId) => () => void;
    handleCancelClick: (id: GridRowId) => () => void;
    handleDeleteClick: (id: GridRowId) => () => void;
};

type GetActionsForRowProps = {
    id: GridRowId;
    rowModesModel: RowModesModel;
    handlers: Handlers;
};

export const getActionsForRow = ({ id, rowModesModel, handlers }: GetActionsForRowProps) => {
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    if (isInEditMode) {
        return [
            <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={handlers.handleSaveClick(id)}
                key="save"
            />,
            <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handlers.handleCancelClick(id)}
                color="inherit"
                key="cancel"
            />,
        ];
    }

    return [
        <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handlers.handleEditClick(id)}
            color="inherit"
            key="edit"
        />,
        <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handlers.handleDeleteClick(id)}
            color="inherit"
            key="delete"
        />,
    ];
};
