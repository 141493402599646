// AlertContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Alert, Snackbar } from '@mui/material';
import AlertDialog from '../components/AlertDialog';
import { AlertService } from '../services/AlertService';

export type AlertSeverity = 'success' | 'info' | 'warning' | 'error' | 'criticalerror' | 'question';

interface AlertContextProps {
    showAlert: (title: string, severity: AlertSeverity, message?: string) => Promise<boolean | void>;
    clearAlert: () => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [alert, setAlert] = useState<{ title: string; severity: AlertSeverity; message?: string; } | null>(null);
    const [confirmation, setConfirmation] = useState<{ title: string; resolve: (value: boolean) => void } | null>(null);

    const showAlert = (title: string, severity: AlertSeverity, message?: string): Promise<boolean | void> => {
        if (severity === 'question') {
            return new Promise((resolve) => {
                setConfirmation({ title, resolve });
            });
        }
        setAlert({ title, message, severity });
        return Promise.resolve();
    };

    const clearAlert = () => setAlert(null);

    const handleConfirm = () => {
        if (confirmation) {
            confirmation.resolve(true);
            setConfirmation(null);
        }
    };

    const handleCancel = () => {
        if (confirmation) {
            confirmation.resolve(false);
            setConfirmation(null);
        }
    };

    // Initialize AlertService with the showAlert method
    React.useEffect(() => {
        AlertService.initialize({ showAlert });
    }, []);

    return (
        <AlertContext.Provider value={{ showAlert, clearAlert }}>
            {children}
            {alert && !['criticalerror', 'question'].includes(alert.severity) && (
                <Snackbar
                    open={!!alert}
                    autoHideDuration={alert.severity === 'error' ? 60000 : 10000}
                    onClose={clearAlert}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    sx={{
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <Alert onClose={clearAlert} variant="filled" severity={alert.severity as 'success' | 'info' | 'warning' | 'error'} sx={{
                        fontSize: '1rem',
                        padding: '0.5rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '95%',
                        textOverflow: 'ellipsis',
                    }}>
                        {alert.title}
                    </Alert>
                </Snackbar>
            )}

            {(alert?.severity === 'criticalerror' || confirmation) && (
                <AlertDialog
                    title={confirmation ? confirmation.title : alert!.title}
                    message={alert ? alert.message : null}
                    onClose={confirmation ? handleCancel : clearAlert}
                    onConfirm={confirmation ? handleConfirm : undefined}
                    isConfirmation={!!confirmation}
                />
            )}
        </AlertContext.Provider>
    );
};

export const useAlert = (): AlertContextProps => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert must be used within an AlertProvider');
    }
    return context;
};
