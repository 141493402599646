import React from "react";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "./../components/LoadingScreen";
import Api from "../api/api";
// import { useDispatch } from "react-redux";
import { stateData } from "../feature/plodSlice";
import secureLocalStorage from "react-secure-storage";
import getPlodName from "../functions/getPlodName";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";

const Home: React.FunctionComponent = () => {
  // const dispatch = useDispatch();

  let PlodNameDynamic: string | null = useSelector((state: RootState) => state.plodData.plodName);
  let PlodNameInternal: string = getPlodName();
  let PlodName: string = PlodNameDynamic || PlodNameInternal;
  document.title = "Home" + " - " + PlodName;
  // const plodId = useSelector((state: RootState) => state.plodData.plod_id);
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <h2 style={{ textAlign: "center", marginTop: "15px" }}>{PlodName}</h2>
      <LoadingSpinner show={loading} />
    </>
  );
};

export default Home;
