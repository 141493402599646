// handleErrorResponse.ts
import { AlertService } from '../services/AlertService';

export const handleErrorResponse = async (
    error: unknown,
    errorTitle: string = "",
    fallbackErrorMessage: string = "An unknown error has occurred. Please try again."
) => {
    console.log(error);
    let errorMessage = fallbackErrorMessage;

    if (typeof error === 'object' && error !== null && 'response' in error) {
        const response = (error as { response: { data: any } }).response;
        if (response.data) {
            const { Result, Message, "APIException.RedOchre.Cloud": serverMessage } = response.data;
            if (Result === "Failed" && serverMessage) {
                errorMessage = serverMessage;
            }
        }
    }

    await AlertService.showAlert(errorTitle, "criticalerror", errorMessage);
};
