// AlertService.ts
import { AlertSeverity } from '../context/AlertContext';

// Singleton service instance
let alertService: {
    showAlert: (title: string, severity: AlertSeverity, message?: string) => Promise<boolean | void>;
} | null = null;

export const AlertService = {
    showAlert(title: string, severity: AlertSeverity, message?: string): Promise<boolean | void> {
        if (!alertService) {
            console.error("AlertService not initialized. Please wrap your app with AlertProvider.");
            return Promise.resolve();
        }
        return alertService.showAlert(title, severity, message);
    },

    initialize(service: typeof alertService) {
        alertService = service;
    },
};
