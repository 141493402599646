import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../api/api";
import Avatar from "@mui/material/Avatar";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import secureLocalStorage from "react-secure-storage";
import getPlodID from "../functions/getPlodID";
import { SidebarData } from "../components/SidebarData";
import * as FaIcons from "react-icons/fa";;
import Home from '../pages/Home';

const AppLayout = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [access, setAccess] = useState<any>(null);
  let plod_id = secureLocalStorage.getItem("plod_id"); // TODO:cannot have the alwyas has type, need to look into

  const plodId = useSelector((state: RootState) => state.plodData.plod_id);
  const plod = plodId || plod_id;

  const getAccess = async () => {
    const payload = `
    {
      LoggedInTeamMember() {
        firstname
        lastname
        ID
        Roles() {
            ID
            RoleName
        }
      }
    }`;

    try {
      const { data, status } = await Api.post(
        `api/Plods/ID_${plod}/LoggedInTeamMember/~GraphQL`,
        payload
      );

      if (status == 200) {
        const loginMember: any = data?.data?.LoggedInTeamMember[0] || null;
        setAccess(loginMember);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (plod_id) {
      getAccess();
    }
  }, [plod_id]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleLogout = () => {
    secureLocalStorage.clear(); // TODO:Make this a bit more smart.
    window.location.href = "https://login.redochre.cloud";
  };

  const location = useLocation();
  let icon = <FaIcons.FaHome />;
  let pageName: string | undefined = "Home";
  if (location.pathname != "/") {
    pageName = SidebarData.find((item) => item.path === location.pathname)?.title;
  }
  return (
    <div
      style={{
        padding: "0px 0px 0px 250px",
        display: "flex",
        flexDirection: "column",
        height: "100vh",  // Make sure the container takes the full height of the viewport
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",  // Aligns the content vertically centered
          paddingRight: 15,
          background: "#ffffff",
          height: "60px", // Adjust as needed for your header height
        }}
      >
        <Sidebar />
        <div
          style={{
            flex: 1,  // Make sure this div takes available space
            textAlign: "start",  // Centers the text horizontally
            fontSize: "20px",  // Adjust font size as needed
            fontWeight: "bold",
            paddingLeft: "25px"
          }}
        ><Typography variant="h5" sx={{ fontWeight: "700" }}>
            {pageName ?? "error"}
          </Typography>
        </div>
        <IconButton color="inherit" onClick={handleClick}>
          <Avatar>{access?.firstname?.charAt(0) || "M"}</Avatar>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem>{access?.firstname}</MenuItem>
          <MenuItem>{access?.Roles?.[0]?.RoleName}</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
      <Outlet />
    </div>

  );
};

export default AppLayout;
