import { Button, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, TextField, Box, InputLabel } from '@mui/material';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import CustomPopup from "./../components/CustomPopup";
import { DataGrid, GridActionsCellItem, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import renameKey from '../functions/renameKey';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Api from '../api/api';
import { handleErrorResponse } from "../api/handleErrorResponse";
import getPlodID from '../functions/getPlodID';
import getPlodName from '../functions/getPlodName';
import { randomId } from '@mui/x-data-grid-generator';
import { AlertService } from '../services/AlertService';
import CustomNoRowsOverlay from '../components/CustomNoRowsOverlay';
import useStandardEditableRowActions from '../components/useStandardEditableRowActions';
import CustomToolBarWithDropDownToolbar from '../components/CustomToolbarWithDropdown';


const Contracts: React.FunctionComponent = () => {
    document.title = `Contracts - ${getPlodName()}`;
    const plodID = getPlodID();
    const [dataGridError, setDataGridError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const miningCompanySelectorID = useRef<string | null>(null);
    const [miningCompanyOptions, setMiningCompanyData] = useState([]);
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});

    const {
        handleSaveClick,
        handleCancelClick,
        handleEditClick,
        handleRowModesModelChange,
    } = useStandardEditableRowActions({
        dataRows,
        dataRowModesModel,
        setDataRowModesModel,
        setDataRows,
    });

    useEffect(() => {
        if (plodID) fetchData();
    }, [plodID]);

    const fetchDropDownData = async () => {
        miningCompanySelectorID.current = null;
        setMiningCompanyData([]);
        try {
            const body = `
            query Plods {
                Plods(ID_AP_Plod: ${plodID}) {
                    MiningCompanys {
                        label:MiningCompanyName
                        value:ID_EA_MiningCompany
                    }
                }
            }`;
            const { data } = await Api.post(`/api/Plods/~GraphQL`, body);
            const MyData = data?.data.Plods[0].MiningCompanys;
            setMiningCompanyData(MyData);
        } catch (e) {
            setMiningCompanyData([]);
            handleErrorResponse(e, "Error while loading Client selectors"); //TODO: need a better way for this. that actually stops the page.
            setDataGridError(`An error occured while loading Client Selectors, Please try again later.`);
        }
    };

    const fetchData = async () => {
        setDataRows([]);
        setLoading(true);
        setDataGridError(null);
        if (miningCompanySelectorID.current == null) {
            fetchDropDownData();
        }
        else {
            try {
                const { data } = await Api.post(`/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID.current}/Contracts`);
                setDataRows(data);
            } catch (e) {
                setDataRows([]);
                setDataGridError(`An error occured while loading Contracts, Please try again later.`);
            }
        }
        setLoading(false);
    };

    //#region Toolbar header thing 
    //TODO: This needs to have it improved so it actually passes the values in.
    function handleMiningCompanyChange(event: SelectChangeEvent<any>): void {
        miningCompanySelectorID.current = event.target.value;
        fetchData();
    };

    //#endregion


    const handleDeleteClick = (ID: GridRowId) => async () => {
        const currentItemName: string = await dataRows.filter((row) => row.ID === ID)[0].ContractName;
        const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Contract "${currentItemName}".`, "question");
        if (confirmed) {
            try {
                const { data } = await Api.post(
                    `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/~DeleteItem`,
                    JSON.stringify({ ID })
                );
                setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
                await AlertService.showAlert(`Successfully Deleted Contract "${currentItemName}".`, "success");
            } catch (e: unknown) {
                handleErrorResponse(e, `Error Deleting Contract "${currentItemName}".`);
            }
        }
    };

    const validateFields = (newRow: GridRowModel) => {
        const validationErrors = [];
        if (!newRow.ContractName?.trim()) validationErrors.push("Contract Name cannot be empty.");
        if (!newRow.ContractDescription?.trim()) validationErrors.push("Contract Description cannot be empty.");

        return validationErrors;
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        const errors = validateFields(newRow);
        if (errors.length) {
            await AlertService.showAlert(`Contract row has empty required fields.`, 'criticalerror', errors.join("\n"));
            return;
        }
        try {
            const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
            const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
            const { data } = await Api.post(
                `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID}/Contracts/${endpoint}`,
                rawData
            );
            const updatedRow = {
                ...newRow,
                ID: data.ID ?? newRow.ID,
                isNew: false,
            };
            setDataRows((prev) =>
                prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
            );
            await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} Contract "${newRow.ContractName}".`, "success");
            return updatedRow;
        } catch (e) {
            handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} Contract "${newRow.ContractName}".`);
        }
        return newRow;
    };

    //#region for the contract setup button
    const ContractConfigButton = (row: any) => {
        if (row.ID) {
            var contractRowsFiltered: any = dataRows.find((row2: any) => row2.ID == row.ID);
            //console.log(contractRowsFiltered);
            if (contractRowsFiltered) {
                return (
                    <Button href={`contract-setup?MiningCompanyID=${miningCompanySelectorID}&ContractID=${contractRowsFiltered.ID}`}>
                        Contract Setup
                    </Button>
                );
            }
        };
        return (
            <Button disabled>
                Error
            </Button>
        );

    };
    //#endregion

    return (
        <>
            <Box sx={{ height: "90vh", margin: "10px", border: 1, borderColor: '#DEDEDE', borderRadius: 2 }}>
                <DataGrid sx={{ zIndex: 0 }}
                    columns={[
                        {
                            field: 'ContractName',
                            headerName: "Contract Name",
                            editable: true,
                            flex: 1
                        },
                        {
                            field: 'ContractDescription',
                            headerName: "Contract Description",
                            editable: true,
                            flex: 1
                        },
                        {
                            field: 'contractSetup',
                            type: 'actions',
                            headerName: 'Contract Setup',
                            width: 150,
                            cellClassName: 'Contract Setup',
                            getActions: ({ id: ID }) => {
                                const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;

                                return isInEditMode ? [] : [
                                    <ContractConfigButton ID={ID} />
                                ];
                            }
                        },
                        {
                            field: 'actions',
                            type: 'actions',
                            headerName: 'Actions',
                            width: 100,
                            cellClassName: 'actions',
                            getActions: ({ id: ID }) => {
                                const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
                                return isInEditMode ? [
                                    <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
                                    <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(ID)} color="inherit" />,
                                ] : [
                                    <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(ID)} color="inherit" />,
                                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
                                ];
                            },
                        },
                    ]}
                    rows={dataRows}
                    rowModesModel={dataRowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    processRowUpdate={processRowUpdate}
                    editMode="row"
                    disableRowSelectionOnClick
                    loading={loading}
                    getRowId={(row) => row.ID} // Gets the id from database, not the local style one
                    slots={{
                        toolbar: () => (<CustomToolBarWithDropDownToolbar
                            setDataRows={setDataRows} // where setRigs updates the rig rows
                            setDataRowModesModel={setDataRowModesModel} // for setting row modes
                            rowTemplate={{ "ContractName": "" }} // define initial fields for rigs
                            fieldName={"ContractName"} // focus on RigName field
                            itemName={"Contract"}
                            disableItems={dataGridError != null || loading}
                            displayDropDown={true}  // Show the dropdown
                            dropDownOptions={miningCompanyOptions}  // Provide the dropdown options
                            dropDownSelectedID={miningCompanySelectorID.current}  // Current selected value
                            handleDropDownChange={handleMiningCompanyChange}  // Correctly typed change handler
                            dropdownDataName="Client"  // Name to display in the label
                        />),
                        noRowsOverlay: () => (
                            <CustomNoRowsOverlay
                                message={dataGridError ? dataGridError : "No Contract Data"}
                                onRetry={dataGridError ? () => fetchData() : undefined}
                            />
                        ),
                    }}
                    slotProps={{
                        toolbar: {
                            setDataRows,
                            setDataRowModesModel
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default Contracts;