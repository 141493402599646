// App.tsx
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AlertProvider } from "./context/AlertContext";
import RoutesConfig from "./RoutesConfig";

const App: React.FC = () => {
  return (
    <AlertProvider> {/* Single provider for all alerts */}
      <Router>
        <RoutesConfig />
      </Router>
    </AlertProvider>
  );
};

export default App;
