//#region Imports!
import React, { useState } from "react";
import { useEditableRowHandlers } from './useEditableRowHandlers'; // Import reusable handlers
import { getActionsForRow } from './getActionsForRow'; // Adjust the path as needed

//The images.
import Consumables from "./../assets/Consumables.gif";
import Tasks from "./../assets/Tasks.gif";
import DrillingDetails from "./../assets/DrillingDetails.gif";
import Operator from "./../assets/Operator.png";
import ShiftComments from "./../assets/ShiftComments.gif";

//Icons.
import CustomPlodSheetDataGrid from './CustomPlodSheetDataGrid';
//For the grid.
import {
  GridColDef,
  GridEditSingleSelectCell,
  GridEditSingleSelectCellProps,
  GridRowModesModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import { Box, Button, InputLabel, styled, TextareaAutosize, TextField } from "@mui/material";
//Types.
import { GenericDropDownOption } from "../Interfaces/GenericDropDownOption.interface";

//Components.
import NewPlodBox from "../components/newPlodBox";
import Swal from "sweetalert2";
import dayjs, { Dayjs } from "dayjs";
import Api from "../api/api";
import { handleErrorResponse } from "../api/handleErrorResponse";
import { AlertService } from "../services/AlertService";
//#endregion

//#region TYPES

type ChargesOption = GenericDropDownOption & {
  UnitOfMeasurement_Name: string;
  UnitOfMeasurement_ChargeByHours: boolean;
};


type OperatorOption = GenericDropDownOption & {
  ID_EA_PersonnelType: number;
};


type TasksOption = GenericDropDownOption & {
  ChargeType_Locked: boolean;
  ID_EDP_Task_ChargeType: number;
}

type BigFiltersRequest = {
  DPConsumables: GenericDropDownOption[];
  OneOffCharges: GenericDropDownOption[];
  DPTasks: TasksOption[];
  DPPersonnels: OperatorOption[];
  TaskChargeTypes: ChargesOption[];
}

type DrillingDetailsRow = {
  ID: number | string;
  Hole: string;
  From: number;
  To: number;
  Total: number;
  TotalRecovered: number;
  Hours: number;
  Bit: string;
  BitSize: number | string;
};

type OperatorRow = {
  ID: number | string;
  Personnel: number | string;
  PersonnelType: number | string;
  Hours: number;
  Comment: string;
};

type ConsumableRow = {
  ID: number;
  Consumable: number | string;
  Quantity: number;
  Charge: boolean;
};

type TaskRow = {
  ID: number | string;
  Task: number | string;
  Hours: number;
  ChargeType: number | string;
  AltChargeQuantity: number;
  Comment: string;
};

type OneOffChargeRow = {
  ID: number | string;
  OneOffCharge: number | string;
  Quantity: number;
  Comment: string;
};

type UpdatePlodData = {
  Operators: OperatorRow[];
  DrillingDetails: DrillingDetailsRow[];
  Tasks: TaskRow[];
  Consumables: ConsumableRow[];
  OneOffCharges: OneOffChargeRow[];
  ShiftComment: string;
};
type NewPlodData = UpdatePlodData & {
  Date: string;
  DayNight: string;
  Status: string;
  Contract: string;
  MiningCompany: string;
  DrillingProgram: string;
  Site: string;
  Rig: string;


};
//#endregion

const PlodDetails = (props: {
  save: boolean;
  plodID: string;
  drillingProgramSelectorID: string;
  contractSelectorID: string;
  miningCompanySelectorID: string;
  statusFilter: string;
  objectiveFilter: string;
  dayNightFilter: string;
  dateValue: Dayjs | null;
  rigFilter: string;
  siteFilter: string;
  shiftID: string;
}) => {
  const shiftID = props.shiftID;
  const miningCompanyID = props.miningCompanySelectorID;
  const contractID = props.contractSelectorID;
  const drillingProgramID = props.drillingProgramSelectorID;

  const [operatorRows, setOperatorRows] = useState<OperatorRow[]>([]); // Assuming operatorRows is of type any[]
  const [drillingDetailsRows, setDrillingDetailsRows] = React.useState<DrillingDetailsRow[]>([]);
  const [taskRows, setTaskRows] = React.useState<TaskRow[]>([]);
  const [consumableRows, setConsumableRows] = React.useState<ConsumableRow[]>([]);
  const [oneOffChargeRows, setOneOffChargeRows] = React.useState<OneOffChargeRow[]>([]);
  const [shiftComment, setShiftComments] = React.useState<string>("");
  const [operatorRowModesModel, setOperatorRowModesModel] = React.useState<GridRowModesModel>({});
  const [operatorOptions, setOperatorOptions] = React.useState<OperatorOption[]>([]);
  const [operatorTypeOptions, setOperatorTypeOptions] = React.useState<GenericDropDownOption[]>([]);
  const [drillingDetailsRowModesModel, setDrillingDetailsRowModesModel] = React.useState<GridRowModesModel>({});
  const [bitSizeOptions, setBitSizeOptions] = React.useState<GenericDropDownOption[]>([]);
  const [taskRowModesModel, setTaskRowModesModel] = React.useState<GridRowModesModel>({});
  const [taskOptions, setTaskOptions] = React.useState<TasksOption[]>([]);
  const [consumableRowModesModel, setConsumableRowModesModel] = React.useState<GridRowModesModel>({});
  const [consumableOptions, setConsumableOptions] = React.useState<GenericDropDownOption[]>([]);
  const [oneOffChargeRowModesModel, setOneOffChargeRowModesModel] = React.useState<GridRowModesModel>({});
  const [oneOffChargeOptions, setOneOffChargeOptions] = React.useState<GenericDropDownOption[]>([]);
  const [taskChargeOptions, setTaskChargeOptions] = React.useState<ChargesOption[]>([]);

  const [rowTask, setRowTask] = React.useState<any>("");


  React.useEffect(() => {
    if (drillingProgramID) {
      setOperatorRows([]);
      setDrillingDetailsRows([]);
      setTaskRows([]);
      setConsumableRows([]);
      setOneOffChargeRows([]);
      setShiftComments("");
    }
  }, [drillingProgramID]);

  //TODO: MAKE THIS A GRAPHQL OR SINGLE QUERY, IT IS MASSIVE AND TOO MANY QUERIES!
  //#region Fetching the existing shift data 

  React.useEffect(() => {
    const fetchPopulatingData = async () => {
      if (
        miningCompanyID != "0" &&
        contractID != "0" &&
        drillingProgramID != "0" &&
        shiftID != null &&
        shiftID != "0"
      ) {

        //Fetch basic data.
        Api.post(
          `/api/Plods/ID_${props.plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/Shifts/ID_${shiftID}/~GetEntireShift`,
        )
          .then(({ data }) => {

            let MyData: NewPlodData = data;
            setOperatorRows(MyData.Operators);
            setDrillingDetailsRows(MyData.DrillingDetails);
            setTaskRows(MyData.Tasks);
            setConsumableRows(MyData.Consumables);
            setOneOffChargeRows(MyData.OneOffCharges);

            setShiftComments(MyData.ShiftComment);
          })
          .catch((err) => console.log(err.message));
      }
    };
    fetchPopulatingData();
  }, [shiftID, drillingProgramID]);
  //#endregion


  //#region Operators
  const operatorHandlers = useEditableRowHandlers({
    rowModesModel: operatorRowModesModel,
    setRowModesModel: setOperatorRowModesModel,
    rows: operatorRows,
    setRows: setOperatorRows,
  });

  //This so it auto selects a personnel type by default
  function CustomOperatorEditComponent(props: GridEditSingleSelectCellProps) {
    const apiRef = useGridApiContext();
    const handleValueChange = async (e: any) => {
      const editedRow: OperatorOption | undefined = operatorOptions.find(
        (row: OperatorOption) => row.value === e.target.value
      );
      const personnelType: number = editedRow!.ID_EA_PersonnelType;
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: "PersonnelType",
        value: personnelType,
      });
    };
    return (
      <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
    );
  }

  const operatorColumns: GridColDef<any>[] = [
    {
      field: "Personnel",
      headerName: "Personnel",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: operatorOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => (
        <CustomOperatorEditComponent
          {...params}
        />
      ),
    },
    {
      field: "PersonnelType",
      headerName: "Type Override",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: operatorTypeOptions,
    },
    {
      field: "Hours",
      headerName: "Hours",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Comment",
      headerName: "Comments",
      flex: 5,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) =>
        getActionsForRow({
          id,
          rowModesModel: operatorRowModesModel,
          handlers: operatorHandlers,
        }),
    },
  ];


  //#region Drilling Details
  const drillingDetailsHandlers = useEditableRowHandlers({
    rowModesModel: drillingDetailsRowModesModel,
    setRowModesModel: setDrillingDetailsRowModesModel,
    rows: drillingDetailsRows,
    setRows: setDrillingDetailsRows,
  });

  const drillingDetailsColumns: GridColDef<any>[] = [
    {
      field: "Hole",
      headerName: "Hole",
      flex: 2,
      editable: true,
    },
    {
      field: "From",
      headerName: "From",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "To",
      headerName: "To",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Total",
      headerName: "Total",
      flex: 1,
      editable: false,
      type: "number",
      valueGetter: (params) => params.row.To - params.row.From,
    },
    {
      field: "TotalRecovered",
      headerName: "Total Recovered",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Hours",
      headerName: "Hours",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Bit",
      headerName: "Bit #",
      flex: 1,
      editable: true,
    },
    {
      field: "BitSize",
      headerName: "Bit Size",
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: bitSizeOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) =>
        getActionsForRow({
          id,
          rowModesModel: drillingDetailsRowModesModel,
          handlers: drillingDetailsHandlers,
        }),
    },
  ];
  //#endregion

  //#region Tasks
  const taskHandlers = useEditableRowHandlers({
    rowModesModel: taskRowModesModel,
    setRowModesModel: setTaskRowModesModel,
    rows: taskRows,
    setRows: setTaskRows,
  });

  //TODO: Move this into like a style sheet or smthing.
  // Styled InputLabel to customize its styles
  const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    color: 'black',
    marginLeft: '10px', // Space between label and text field
    whiteSpace: 'nowrap', // Prevent label from wrapping
    fontSize: '14px',
    '&.Mui-disabled': {
      color: 'black', // Set disabled label color to black
    },
    flexShrink: 0
  }));

  // Styled TextField to customize its styles
  const CustomTextField = styled(TextField)(({ theme }) => ({
    marginLeft: '10px', // Space between label and text field
    '& .MuiInputBase-root': {
      color: 'black', // Change text color
      border: 'none', // Remove borders
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none', // Remove bottom border
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none', // Remove bottom border on focus
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', // Remove border for outlined variant
      },
    },
    '& .MuiFilledInput-root': {
      '&:before': {
        borderBottom: 'none', // Remove bottom border for filled variant
      },
      '&:after': {
        borderBottom: 'none', // Remove bottom border on focus for filled variant
      },
    },
  }));
  //This so it auto selects a personnel type by default
  function CustomTaskChargeEditComponent(props: GridEditSingleSelectCellProps) {
    const apiRef = useGridApiContext();
    const handleValueChange = async (e: any) => {
      const editedRow: TasksOption | undefined = taskOptions.find(
        (row: TasksOption) => row.value === e.target.value
      );
      const chargeType: number = editedRow!.ID_EDP_Task_ChargeType;
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: "ChargeType",
        value: chargeType,
      });
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: "AltChargeQuantity",
        value: null,
      }); // just makes it retrigger itself so it works right.
    };
    return (
      <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
    );
  }

  const taskColumns: GridColDef<any>[] = [
    {
      field: "Task",
      headerName: "Task",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: taskOptions,
      renderEditCell: (params: GridEditSingleSelectCellProps) => {
        const { row } = params;
        const currentTask = taskOptions.find(x => x.value === row?.Task);
        setRowTask(currentTask);
        return (
          <CustomTaskChargeEditComponent
            {...params}
          />
        );
      }
    },
    {
      field: "Hours",
      headerName: "Hours",
      flex: 1,
      editable: true,
      type: "number",
    },

    {
      field: "ChargeType",
      headerName: "Charge",
      flex: 2,
      editable: true,
      type: "singleSelect",
      valueOptions: taskChargeOptions,
      renderEditCell: (params) => {
        return (
          <GridEditSingleSelectCell
            sx={{ width: "100%" }}
            {...params}
            onValueChange={(e) => {
              const newValue = e.target.value;
              params.api.setEditCellValue({ id: params.id, field: 'ChargeType', value: newValue });
              params.api.setEditCellValue({ id: params.id, field: 'AltChargeQuantity', value: null });
            }}
            disabled={rowTask?.ChargeType_Locked} // Disable if ChargeType_Locked is true
          />
        );
      },
    },
    {
      field: "AltChargeQuantity",
      headerName: "Alt Charge",
      flex: 2,
      editable: true,
      renderCell: (params) => {
        const chargeItem = taskChargeOptions.find(x => x.value === params.row.ChargeType);
        if (!chargeItem || chargeItem.UnitOfMeasurement_ChargeByHours == true) {
          return <CustomInputLabel disabled />; // Display value without editing
        }
        // Render TextField for editing if AltChargeType has a value
        const label = chargeItem.UnitOfMeasurement_Name + " QTY: "; // Variable for the label
        return (
          <Box display="flex" alignItems="center">
            <CustomInputLabel disabled htmlFor="custom-text-field" sx={{ marginLeft: '0px' }}>{label}</CustomInputLabel>
            <CustomInputLabel disabled htmlFor="custom-text-field">{params.value} </CustomInputLabel>
          </Box>
        );
      },
      renderEditCell: (params) => {
        // const altChargeTypeValue = params.row.charge;
        const chargeItem = taskChargeOptions.find(x => x.value === params.row.ChargeType);
        if (!chargeItem || chargeItem.UnitOfMeasurement_ChargeByHours == true) {
          return <CustomInputLabel disabled />;// Display value without editing
        }
        // Render TextField for editing if AltChargeType has a value
        const label = chargeItem.UnitOfMeasurement_Name + " QTY: "; // Variable for the label
        return (
          <Box display="flex" alignItems="center">
            <CustomInputLabel sx={{ fontSize: '16px' }} disabled>{label}</CustomInputLabel>
            <CustomTextField
              sx={{ fontSize: '16px' }}
              variant="standard"
              value={params.value}
              type="number"
              onChange={(event) => {
                params.api.setEditCellValue({ id: params.id, field: 'AltChargeQuantity', value: event.target.value });
              }} />
          </Box>
        );
      },
    },
    {
      field: "Comment",
      headerName: "Comment",
      flex: 4,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) =>
        getActionsForRow({
          id,
          rowModesModel: taskRowModesModel,
          handlers: taskHandlers,
        }),
    },
  ];
  //#endregion

  const consumableHandlers = useEditableRowHandlers({
    rowModesModel: consumableRowModesModel,
    setRowModesModel: setConsumableRowModesModel,
    rows: consumableRows,
    setRows: setConsumableRows,
  });

  const consumableColumns: GridColDef<any>[] = [
    {
      field: "Consumable",
      headerName: "Consumable",
      flex: 4,
      editable: true,
      type: "singleSelect",
      valueOptions: consumableOptions,
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Charge",
      headerName: "Charge?",
      flex: 1,
      editable: true,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) =>
        getActionsForRow({
          id,
          rowModesModel: consumableRowModesModel,
          handlers: consumableHandlers,
        }),
    },
  ];

  const oneOffChargeHandlers = useEditableRowHandlers({
    rowModesModel: oneOffChargeRowModesModel,
    setRowModesModel: setOneOffChargeRowModesModel,
    rows: oneOffChargeRows,
    setRows: setOneOffChargeRows,
  });

  const oneOffChargeColumns: GridColDef<any>[] = [
    {
      field: "OneOffCharge",
      headerName: "One Off Charge",
      flex: 4,
      editable: true,
      type: "singleSelect",
      valueOptions: oneOffChargeOptions,
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "Comment",
      headerName: "Comments",
      flex: 2.5,
      editable: true,
      type: "string",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) =>
        getActionsForRow({
          id,
          rowModesModel: oneOffChargeRowModesModel,
          handlers: oneOffChargeHandlers,
        }),
    },
  ];
  //#endregion


  //#region Populating Deep Data
  React.useEffect(() => {
    const fetchData = async () => {
      if (drillingProgramID != "0") {
        await Api.post(
          `/api/Plods/ID_${props.plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/~GetAllValuesForShift`
        )
          .then(({ data }) => {
            try {
              let DataToUse: BigFiltersRequest = data;
              setConsumableOptions(DataToUse.DPConsumables);
              setOneOffChargeOptions(DataToUse.OneOffCharges);
              setTaskOptions(DataToUse.DPTasks);
              setOperatorOptions(DataToUse.DPPersonnels);
              setTaskChargeOptions(DataToUse.TaskChargeTypes);
            } catch {
              setConsumableOptions([]);
              setOneOffChargeOptions([]);
              setTaskOptions([]);
              setOperatorOptions([]);
              setTaskChargeOptions([]);
            }
          })
          .catch((e) => Swal.fire("failed to fetch data.", "", "error"));
      } else {
        setConsumableOptions([]);
      }
    };
    if (drillingProgramID) {
      fetchData();
    }
  }, [drillingProgramID]);
  //TODO: Look at adding this data into big request up top - will be double ups but could be neater
  //#region populate data.
  React.useEffect(() => {
    const fetchData = async () => {
      const body = `
        query Plods {
          Plods(ID_AP_Plod: ${props.plodID}) {
            PersonnelTypes {
              value:ID
              label:PersonnelTypeName
            }
            BitSizes {
              value:ID
              label:Size
            }
          }
        }`;
      await Api.post(`/api/Plods/~GraphQL`, body)
        .then(({ data }) => {
          try {
            setBitSizeOptions(data?.data.Plods[0].BitSizes);
            setOperatorTypeOptions(data?.data.Plods[0].PersonnelTypes);
          } catch {
            setBitSizeOptions([]);
            setOperatorTypeOptions([]);
          }
        })
        .catch((e) => Swal.fire("failed to fetch data.", "", "error"));
    };
    if (props.plodID) {
      fetchData();
    };
  }, [props.plodID]);
  //#endregion
  const validateFields = (): string[] => {
    const validationErrors: string[] = [];
    if (operatorRows.length == 0) validationErrors.push("Personnel cannot be empty.");
    if (drillingDetailsRows.length == 0) validationErrors.push("Drilling Details cannot be empty.");
    if ((drillingDetailsRows.reduce((total: any, obj: any) => (obj.Hours) + total, 0) + taskRows.reduce((total: any, obj: any) => (obj.Hours) + total, 0)) != 12) {
      validationErrors.push("Total hours must add up to 12.");
    };
    if (props.rigFilter == '') validationErrors.push("You must select a Rig.");
    if (props.siteFilter == '') validationErrors.push("You must select a Site.");
    if (props.dayNightFilter == '') validationErrors.push("You must select a Shift.");
    if (props.statusFilter == '') validationErrors.push("You must select a Status.");
    if (props.objectiveFilter == '') validationErrors.push("You must select an Objective.");
    if (!dayjs(props.dateValue).isValid()) validationErrors.push("You must select a valid Date.");
    if (validationErrors.length < 1) {
      const validations = [
        //Operators
        { check: operatorRows.some((element: OperatorRow) => element.Personnel == ''), message: "Personnel cannot be empty." },
        { check: operatorRows.some((element: OperatorRow) => element.PersonnelType == ''), message: "Personnel Type cannot be empty." },
        { check: operatorRows.some((element: OperatorRow) => element.Hours < 0), message: "Hours cannot be negative." },
        //Drilling Details
        { check: drillingDetailsRows.some((element: DrillingDetailsRow) => !element.Hole.trim()), message: "Hole cannot be empty." },
        { check: drillingDetailsRows.some((element: DrillingDetailsRow) => element.From < 0), message: "From cannot be negative." },
        { check: drillingDetailsRows.some((element: DrillingDetailsRow) => element.To < 0), message: "To cannot be negative." },
        { check: drillingDetailsRows.some((element: DrillingDetailsRow) => element.TotalRecovered < 0), message: "Total recovered cannot be negative." },
        { check: drillingDetailsRows.some((element: DrillingDetailsRow) => element.Hours < 0), message: "Hours cannot be negative." },
        { check: drillingDetailsRows.some((element: DrillingDetailsRow) => !element.Bit.trim()), message: "Bit # cannot be empty." },
        { check: drillingDetailsRows.some((element: DrillingDetailsRow) => element.BitSize == ''), message: "Bit Size cannot be empty." },
        //Task
        { check: taskRows.some((element: TaskRow) => element.Task == ''), message: "Task cannot be empty." },
        { check: taskRows.some((element: TaskRow) => element.Hours < 0), message: "Hours cannot be negative." },
        { check: taskRows.some((element: TaskRow) => element.ChargeType == ''), message: "Charge cannot be empty." },
        //TODO: Make it also check if there is a needed alt charge quantity one.
        //Consumables
        { check: consumableRows.some((element: ConsumableRow) => element.Consumable == ''), message: "Consumable cannot be empty." },
        { check: consumableRows.some((element: ConsumableRow) => element.Quantity < 0), message: "Quantity cannot be negative." },
        //One Off Charges
        { check: oneOffChargeRows.some((element: OneOffChargeRow) => element.OneOffCharge == ''), message: "One Off Charge cannot be empty." },
        { check: oneOffChargeRows.some((element: OneOffChargeRow) => element.Quantity < 0), message: "Quantity cannot be negative." },
      ];

      validations.forEach(({ check, message }) => {
        if (check) {
          validationErrors.push(message);
        }
      });
      //Loop here and make sure data is correct.
    }
    return validationErrors;
  };

  const handleSubmitButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const createDataJSON = (): NewPlodData | UpdatePlodData => {
      return {
        Date: props.dateValue?.format("MM/DD/YYYY").toString() || "",
        DayNight: props.dayNightFilter,
        Site: props.siteFilter,
        Rig: props.rigFilter,
        Status: props.statusFilter,
        MiningCompany: miningCompanyID,
        Contract: contractID,
        DrillingProgram: drillingProgramID,
        Operators: operatorRows,
        DrillingDetails: drillingDetailsRows,
        Tasks: taskRows,
        Consumables: consumableRows,
        OneOffCharges: oneOffChargeRows,
        ShiftComment: shiftComment,
      };
    };

    const dataJSON = createDataJSON();

    const errors: string[] = validateFields();
    if (errors.length) {
      await AlertService.showAlert(`Error with data.`, 'criticalerror', errors.join("\n"));
    }

    else {
      try {
        const url = props.save
          ? `/api/Plods/ID_${props.plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/~AddEntireShift`
          : `/api/Plods/ID_${props.plodID}/MiningCompanys/ID_${miningCompanyID}/Contracts/ID_${contractID}/DrillingPrograms/ID_${drillingProgramID}/Shifts/ID_${shiftID}/~UpdateEntireShift`;
        const response = await Api.post(url, dataJSON);
        await AlertService.showAlert(`Successfully ${props.save ? "Added" : "Updated"} Shift.`, "success");
      } catch (e) {
        handleErrorResponse(e, props.save ? "failed to save data." : "failed to update data.");
      }
    };
  };


  const sections = [
    {
      color: "rgb(95, 45, 135)",
      image: Operator,
      text: "Personnel",
      columns: operatorColumns,
      rows: operatorRows,
      rowModesModel: operatorRowModesModel,
      setRows: setOperatorRows,
      setRowModesModel: setOperatorRowModesModel,
      fieldToFocus: "Personnel",
      buttonText: "Add Personnel",
      initialRow: { Personnel: '', PersonnelType: '', Hours: 0, Comment: '' }
    },
    {
      color: "rgb(242, 107, 49)",
      image: DrillingDetails,
      text: "Drilling Details",
      columns: drillingDetailsColumns,
      rows: drillingDetailsRows,
      rowModesModel: drillingDetailsRowModesModel,
      setRows: setDrillingDetailsRows,
      setRowModesModel: setDrillingDetailsRowModesModel,
      fieldToFocus: "Hole",
      buttonText: "Add Drilling Details",
      initialRow: { Hole: '', From: 0, To: 0, Total: 0, TotalRecovered: 0, Hours: 0, Bit: '', BitSize: '' }
    },
    {
      color: "rgb(60, 124, 59)",
      image: Tasks,
      text: "Tasks",
      columns: taskColumns,
      rows: taskRows,
      rowModesModel: taskRowModesModel,
      setRows: setTaskRows,
      setRowModesModel: setTaskRowModesModel,
      fieldToFocus: "Task",
      buttonText: "Add Task",
      initialRow: { Task: '', Hours: 0, ChargeType: '', Comment: '' }
    },
    {
      color: "rgb(0, 85, 162)",
      image: Consumables,
      text: "Consumables",
      columns: consumableColumns,
      rows: consumableRows,
      rowModesModel: consumableRowModesModel,
      setRows: setConsumableRows,
      setRowModesModel: setConsumableRowModesModel,
      fieldToFocus: "Consumable",
      buttonText: "Add Consumable",
      initialRow: { Consumable: '', Quantity: 0, Charge: false }
    },
    {
      color: "rgb(23, 185, 100)",
      image: Consumables,
      text: "One Off Charges",
      columns: oneOffChargeColumns,
      rows: oneOffChargeRows,
      rowModesModel: oneOffChargeRowModesModel,
      setRows: setOneOffChargeRows,
      setRowModesModel: setOneOffChargeRowModesModel,
      fieldToFocus: "OneOffCharge",
      buttonText: "Add One Off Charge",
      initialRow: { OneOffCharge: '', Quantity: 1, Comment: "" }
    },
  ];
  return (
    <>
      {sections.map(({ color, image, text, columns, rows, rowModesModel, setRows, setRowModesModel, fieldToFocus, buttonText, initialRow }) => (
        <NewPlodBox color={color} image={image} text={text} key={text}>
          <CustomPlodSheetDataGrid
            columns={columns}
            rows={rows}
            rowModesModel={rowModesModel}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            fieldToFocus={fieldToFocus}
            buttonText={buttonText}
            initialRow={initialRow}
            shiftID={shiftID}
            drillingProgramID={drillingProgramID}
          />
        </NewPlodBox>
      ))}

      <NewPlodBox color="rgb(10, 8, 8)" image={ShiftComments} text="Comments about the shift" height="30vh">
        <TextareaAutosize style={{ width: "100%" }} value={shiftComment} onChange={(e) => setShiftComments(e.target.value)} minRows={5} />
      </NewPlodBox>

      <Button variant="contained" onClick={handleSubmitButtonClick} style={{ marginLeft: 25, marginBottom: 50 }}>
        Submit
      </Button>
    </>
  );
};

export default PlodDetails;
