//#region Imports!
import React, { useEffect, useRef } from "react";

//The images.
import ShiftInformation from "./../assets/ShiftInformation.gif";

import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

//Tools.
import { FilterSpacing } from "../components/FilterSpacing";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//Types.
import { IMiningCompany } from "../Interfaces/MiningCompany.interface";
import { IContract } from "../Interfaces/Contract.interface";
import { IDrillingProgram } from "../Interfaces/DrillingProgram.interface";
import { Rig } from "./../types/rigType";
import { Site } from "./../types/siteType";
import { IObjective } from "./../Interfaces/Objectives.interface";
//Components.
import NewPlodBox from "../components/newPlodBox";

import PlodDetails from "./../components/PlodDetailsComponent";
import Api from "../api/api";
import { handleErrorResponse } from "../api/handleErrorResponse";
import getPlodName from "../functions/getPlodName";
import getPlodID from "../functions/getPlodID";
//#endregion


type OptionsDataResult = {
  Sites: Site[];
  Rigs: Rig[];
  Statuses: StatusData[];
  Objectives: IObjective[]
}
type StatusData = {
  ID_WF_Status: number;
  StatusOrder: number;
  StatusName: string;
}

const NewPlod: React.FunctionComponent = () => {
  document.title = "New Plod" + " - " + getPlodName();
  const plodID = getPlodID();

  //#region Filters.
  const miningCompanySelectorID = useRef<string>('');
  const contractSelectorID = useRef<string>('');
  const drillingProgramSelectorID = useRef<string>('');

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const [miningCompanyData, setMiningCompanyData] = React.useState<IMiningCompany[]>([]);
  const [contractData, setContractData] = React.useState<IContract[]>([]);
  const [drillingProgramData, setDrillingProgramData] = React.useState<IDrillingProgram[]>([]);

  const [siteData, setSiteData] = React.useState<Site[]>([]);
  const [rigData, setRigData] = React.useState<Rig[]>([]);
  const [objectiveData, setObjectiveData] = React.useState<any[]>([]);
  const [statusData, setStatusData] = React.useState<StatusData[]>([]);

  const [dateValue, setDateValue] = React.useState<Dayjs | null>(null);

  const [shiftFilter, setShiftFilter] = React.useState<string>('');
  const handleShiftFilterChange = (event: SelectChangeEvent) => {
    setShiftFilter(event.target.value as string);
  };

  const [objectiveFilter, setObjectiveFilter] = React.useState<string>('');
  const handleObjectiveFilterChange = (event: SelectChangeEvent) => {
    setObjectiveFilter(event.target.value as string);
  };
  const [statusFilter, setStatusFilter] = React.useState<string>('');
  const handleStatusFilterChange = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value as string);
  };
  const [siteFilter, setSiteFilter] = React.useState<string>('');
  const handleSiteFilterChange = (event: SelectChangeEvent) => {
    setSiteFilter(event.target.value as string);
  };

  const [rigFilter, setRigFilter] = React.useState<string>('');
  const handleRigFilterChange = (event: SelectChangeEvent) => {
    setRigFilter(event.target.value as string);
  };

  //#region Filters Populating.
  useEffect(() => {
    fetchBaseData(); //TODO: Surely there has to be a better way to do this
  }, []);
  let hasFetched = false;
  const fetchBaseData = async () => {
    if (hasFetched) return;
    hasFetched = true;
    await fetchMiningCompanyData();
  };

  const handleMiningCompanySelectorChange = (event: SelectChangeEvent<string>) => {
    miningCompanySelectorID.current = event.target.value;
    fetchContractData();
  };

  const fetchMiningCompanyData = async () => {
    miningCompanySelectorID.current = '';
    setMiningCompanyData([]);
    contractSelectorID.current = '';
    setContractData([]);
    ClearAllCommonFilters();
    Api.post(
      `/api/Plods/ID_${plodID}/MiningCompanys/`
    )
      .then(({ data }) => {
        setMiningCompanyData(data);
        if (data.length != 0) {
          miningCompanySelectorID.current = data[0].ID_EA_MiningCompany;
          fetchContractData();
        }
      })
      .catch((e) => {
        handleErrorResponse(e, "Error fetching Clients");
      });
  };

  const handleContractSelectorChange = (event: SelectChangeEvent<string>) => {
    contractSelectorID.current = event.target.value;
    fetchDrillingProgramData();
  };

  const fetchContractData = async () => {
    contractSelectorID.current = ('');
    setContractData([]);
    ClearAllCommonFilters();
    Api.post(
      `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID.current}/Contracts`
    )
      .then(({ data }) => {
        setContractData(data);
        if (data.length != 0) {
          contractSelectorID.current = data[0].ID_EC_Contract;
          fetchDrillingProgramData();
        }
      })
      .catch((e) => {
        handleErrorResponse(e, "Error fetching Contracts");
      });
  };

  const handleDrillingProgramSelectorChange = (event: SelectChangeEvent<string>,) => {
    drillingProgramSelectorID.current = (event.target.value);
    fetchOptionsData();
  };
  const fetchDrillingProgramData = async () => {
    ClearAllCommonFilters();
    Api.post(
      `/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID.current}/Contracts/ID_${contractSelectorID.current}/DrillingPrograms`
    )
      .then(({ data }) => {
        setDrillingProgramData(data);
        if (data.length != 0) {
          drillingProgramSelectorID.current = data[0].ID_EDP_Drilling_Program;
          fetchOptionsData();
        }
      })
      .catch((e) => {
        handleErrorResponse(e, "Error fetching Drilling Programs");
      });
  };

  const ClearAllCommonFilters = () => {
    drillingProgramSelectorID.current = '';
    setDrillingProgramData([]);
    setSiteData([]);
    setSiteFilter('');
    setRigData([]);
    setRigFilter('');
    setStatusData([]);
    setStatusFilter('');
    setObjectiveData([]);
    setObjectiveFilter('');
  };

  const fetchOptionsData = async () => {
    try {
      const { data } = await Api.post(`/api/Plods/ID_${plodID}/MiningCompanys/ID_${miningCompanySelectorID.current}/Contracts/ID_${contractSelectorID.current}/DrillingPrograms/ID_${drillingProgramSelectorID.current}/~GetAllValuesForNewPlod`);
      const { Sites, Rigs, Statuses, Objectives }: OptionsDataResult = data;
      setSiteData(Sites ?? (() => { throw new Error('Sites is missing'); })());
      setRigData(Rigs ?? (() => { throw new Error('Rigs is missing'); })());
      setStatusData(Statuses ?? (() => { throw new Error('Statuses is missing'); })());
      setObjectiveData(Objectives ?? (() => { throw new Error('Objectives is missing'); })());
    } catch (e) {
      handleErrorResponse(e, "Error while loading selectors");
    }
  };

  return (
    <>
      <NewPlodBox
        color="rgb(145, 149, 154)"
        image={ShiftInformation}
        text="Shift Information"
        height="100%"
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row', // Arrange items horizontally
          alignItems: 'center', // Align items vertically centered
          gap: 2,               // Space between items
          borderBottom: 1,
          marginTop: 2,
          borderColor: 'divider',
        }}>

          <Stack spacing={0} padding={"5px"}>
            <FormHelperText>Client</FormHelperText>
            <Select
              id="ClientSelector"
              value={miningCompanySelectorID.current ?? ''}
              onChange={handleMiningCompanySelectorChange}
              disabled={miningCompanyData.length == 0 || errorMessage != null}
              style={{ width: 250 }}
              displayEmpty
            >
              <MenuItem value="" key="Unselected" disabled>
                {miningCompanyData.length == 0 ? "No Clients " : "Select a Client"}
              </MenuItem>
              {miningCompanyData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    key={data["ID_EA_MiningCompany"]}
                    value={data["ID_EA_MiningCompany"]}
                  >
                    {data["MiningCompanyName"]}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>

          <Stack spacing={0} padding={"5px"}>
            <FormHelperText>Contract</FormHelperText>
            <Select
              id="ContractSelector"
              value={contractSelectorID.current ?? ''}
              onChange={handleContractSelectorChange}
              disabled={contractData.length == 0 || errorMessage != null}
              style={{ width: 250 }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                {contractData.length == 0 ? "No Contracts " : "Select a Contract"}
              </MenuItem>
              {contractData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    key={data["ID_EC_Contract"]}
                    value={data["ID_EC_Contract"]}
                  >
                    {data["ContractName"]}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack spacing={0} padding={"5px"}>
            <FormHelperText>Drilling Program</FormHelperText>
            <Select
              id="DrillingProgramSelector"
              value={drillingProgramSelectorID.current ?? ''}
              onChange={handleDrillingProgramSelectorChange}
              disabled={drillingProgramData.length == 0 || errorMessage != null}
              style={{ width: 250 }}
              displayEmpty
            >
              <MenuItem value="" key="Unselected" disabled>
                {drillingProgramData.length == 0 ? "No Drilling Programs " : "Select a Drilling Program"}
              </MenuItem>
              {drillingProgramData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    key={data["ID_EDP_Drilling_Program"]}
                    value={data["ID_EDP_Drilling_Program"]}
                  >
                    {data["DrillingProgramName"]}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
        }}>
          <Stack spacing={0} padding={"5px"}>
            <FormHelperText>Date</FormHelperText>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dateValue}
                disabled={errorMessage != null}
                onChange={(newValue) => setDateValue(newValue)}
                format="DD/MM/YYYY"
                sx={{ width: 250 }}
              />
            </LocalizationProvider>
          </Stack>
          <Stack spacing={0} padding={"5px"}>
            <FormHelperText>Shift</FormHelperText>
            <Select
              id="shiftFilter"
              value={shiftFilter}
              onChange={handleShiftFilterChange}
              style={{ width: 250 }}
              disabled={errorMessage != null}
              displayEmpty
            >
              <MenuItem value='' disabled>
                {"Select a Shift"}
              </MenuItem>
              <MenuItem value={"Day"} key={"Day"}>
                Day
              </MenuItem>
              <MenuItem value={"Night"} key={"Night"}>
                Night
              </MenuItem>
            </Select>
          </Stack>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row', // Arrange items horizontally
          alignItems: 'center', // Align items vertically centered
          gap: 2,               // Space between items

        }}>
          <Stack spacing={0} padding={"5px"}>
            <FormHelperText>Rig</FormHelperText>
            <Select
              id="RigSelector"
              value={rigFilter}
              onChange={handleRigFilterChange}
              disabled={rigData.length == 0 || errorMessage != null}
              style={{ width: 250 }}
              displayEmpty
            >
              <MenuItem value='' disabled>
                {rigData.length == 0 ? "No Rigs" : "Select a Rig"}
              </MenuItem>
              {rigData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    value={data["ID_EA_Rig"]}
                    key={data["ID_EA_Rig"]}
                  >
                    {data["RigName"]}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack spacing={0} padding={"5px"}>
            <FormHelperText>Site</FormHelperText>
            <Select
              id="SiteSelector"
              value={siteFilter}
              onChange={handleSiteFilterChange}
              disabled={siteData.length == 0 || errorMessage != null}
              style={{ width: 250 }}
              displayEmpty
            >
              <MenuItem value='' disabled>
                {siteData.length == 0 ? "No Sites" : "Select a Site"}
              </MenuItem>
              {siteData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    value={data["ID_EA_Site"]}
                    key={data["ID_EA_Site"]}
                  >
                    {data["SiteName"]}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack spacing={0} padding={"5px"}>
            <FormHelperText>Objective</FormHelperText>
            <Select
              id="ObjectiveSelector"
              value={objectiveFilter}
              onChange={handleObjectiveFilterChange}
              disabled={objectiveData.length == 0 || errorMessage != null}
              style={{ width: 250 }}
              displayEmpty
            >
              <MenuItem value='' disabled>
                {objectiveData.length == 0 ? "No Objectives" : "Select an Objective"}
              </MenuItem>
              {objectiveData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    value={data["ID_EA_Objective"]}
                    key={data["ID_EA_Objective"]}
                  >
                    {data["ObjectiveName"]}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
          <Stack spacing={0} padding={"5px"}>
            <FormHelperText>Status</FormHelperText>
            <Select
              id="StatusSelector"
              value={statusFilter}
              onChange={handleStatusFilterChange}
              disabled={statusData.length == 0 || errorMessage != null}
              style={{ width: 250 }}
              displayEmpty
            >
              <MenuItem value='' disabled>
                {statusData.length == 0 ? "No Statuses" : "Select a Status"}
              </MenuItem>
              {statusData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    value={data["ID_WF_Status"]}
                    key={data["ID_WF_Status"]}
                  >
                    {data["StatusName"]}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
        </Box>
      </NewPlodBox >
      {errorMessage != null ? (
        <Box sx={{ mt: 1, textAlign: 'center' }}>
          <Typography variant="body1" color={"error"}>
            An error occured, Please reload the page or try again later.
          </Typography>
          <Typography variant="body1" color={"error"}>
            {errorMessage}
          </Typography>
        </Box>
      ) : (
        <PlodDetails
          plodID={plodID}
          save={true}
          drillingProgramSelectorID={drillingProgramSelectorID.current}
          contractSelectorID={contractSelectorID.current}
          miningCompanySelectorID={miningCompanySelectorID.current}
          statusFilter={statusFilter}
          objectiveFilter={objectiveFilter}
          dayNightFilter={shiftFilter}
          dateValue={dateValue}
          rigFilter={rigFilter}
          siteFilter={siteFilter}
          shiftID={"0"}
        />
      )}
    </>
  );
};

export default NewPlod;