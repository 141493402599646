import React, { useEffect, useState } from "react";
import { Box, Button, Tab, Tabs } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { randomId } from "@mui/x-data-grid-generator";
import dayjs from "dayjs";
import Api from "../api/api";
import getPlodID from "../functions/getPlodID";
import getPlodName from "../functions/getPlodName";
import { TabPanel } from '../components/TabPanel'; // Import TabPanel component
import { handleErrorResponse } from '../api/handleErrorResponse';
import { AlertService } from "../services/AlertService";
import useStandardEditableRowActions from "../components/useStandardEditableRowActions";


//Need to make types for every single option.
//#region  Type setup - need to move into seperate files at some point. CANNOT GET THESE TO WORK AHSAGFJASDKF I NEED TO
//TODO: Need to look into this at some point.

// import { IConsumable } from "../Interfaces/Consumable.interface";
// import { IDrillingType } from "../Interfaces/DrillingType.interface"; // this is broken idky
// import { GenericDropDownOption } from "../Interfaces/GenericDropDownOption.interface";
// import { ILocation } from "../Interfaces/Location.interface";
// import { IPersonnel } from "../Interfaces/Personnels.interface";
// import { IPersonnelType } from "../Interfaces/PersonnelTypes.interface";
// import { ISite } from "../Interfaces/Sites.interface";
// import { ITask } from "../Interfaces/Task.interface";
// import { ITaskMetric } from "../Interfaces/TaskMetrics.interface";
// import { IUnitOfMeasurement } from "../Interfaces/UnitOfMeasurement.interface";

// type IRowData = IPersonnel[] | IPersonnelType[] | ILocation[] | ITask[] | IUnitOfMeasurement[] | ITaskMetric[] | IConsumable[] | IDrillingType[] | ILocation[];
// type IResponseData = IRoot | IRowData; // reorder this at some point

// interface IRoot {
//   data: IData
// }
// interface IData {
//   Plods: IPlod[]
// }
// interface IPlod {
//   Locations: GenericDropDownOption
//   Sites: ISite[]
// }

//#endregion

const Lists: React.FC = () => {
  document.title = `Lists - ${getPlodName()}`;
  const plodID = getPlodID();
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [locationOptions, setLocationOptions] = useState<{ label: string; value: string }[]>([]);
  const [tabValue, setTabValue] = useState<string>("UnitOfMeasurements");
  const [mainTab, setMainTab] = useState(0);
  const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});


  const {
    handleSaveClick,
    handleCancelClick,
    handleEditClick,
    handleRowModesModelChange,
  } = useStandardEditableRowActions({
    dataRows,
    dataRowModesModel,
    setDataRowModesModel,
    setDataRows,
  });

  useEffect(() => {
    if (plodID) fetchData(tabValue);
  }, [plodID]);

  const fetchData = async (Tab: string) => {
    try {
      const endpoint = Tab === "Sites"
        ? `/api/Plods/~GraphQL`
        : `/api/Plods/ID_${plodID}/${Tab}`;

      const body = Tab === "Sites" ? {
        query: `{
          Plods(ID_AP_Plod: ${plodID}) {
            Locations {
              label: LocationName
              value: ID_EA_Location
            }
            Sites {
              ID
              ID_EA_Site
              ID_AP_Plod
              SiteName
              ID_EA_Location
            }
          }
        }`
      } : undefined;
      const { data } = await Api.post(endpoint, body);
      if (Tab === "Sites") {
        const { Sites, Locations } = data.data.Plods[0];
        setDataRows(Sites);
        setLocationOptions(Locations);
      } else {
        setDataRows(data);
      }
    } catch {
      setDataRows([]);
    }
  };

  const handleDeleteClick = (ID: GridRowId) => async () => {
    const currentItemName: string = await dataRows.filter((row) => row.ID === ID)[0][getTabNameField()];
    const confirmed = await AlertService.showAlert(`Are you sure you want to delete the ${renderTabContent()} "${currentItemName}".`, "question");
    if (confirmed) {
      try {
        const { data } = await Api.post(`/api/Plods/ID_${plodID}/${tabValue}/~DeleteItem`, JSON.stringify({ ID: ID }));
        setDataRows(prev => prev.filter(row => row.ID !== data.ID));
        await AlertService.showAlert(`Successfully Deleted ${renderTabContent()} "${currentItemName}".`, "success");
      } catch (e) {
        handleErrorResponse(e, `Error Deleting ${renderTabContent()} "${currentItemName}".`);
      }
    }
  };

  const validateFields = (newRow: GridRowModel) => {
    const validationErrors = [];
    if (tabValue === "Consumables" && !newRow.ConsumableName?.trim()) validationErrors.push("Consumable Name cannot be empty.");
    if (tabValue === "TaskMetrics" && !newRow.TaskMetric?.trim()) validationErrors.push("Task Metric Name cannot be empty.");
    if (tabValue === "Tasks" && !newRow.TaskName?.trim()) validationErrors.push("Task Name cannot be empty.");
    if (tabValue === "Locations" && !newRow.LocationName?.trim()) validationErrors.push("Location Name cannot be empty.");
    if (tabValue === "PersonnelTypes" && !newRow.PersonnelTypeName?.trim()) validationErrors.push("Personnel Type Name cannot be empty.");
    if (tabValue === "Personnels") {
      if (!newRow.FirstName?.trim()) validationErrors.push("First Name cannot be empty.");
      if (!newRow.LastName?.trim()) validationErrors.push("Last Name cannot be empty.");
    }
    if (tabValue === "DrillingTypes") {
      if (!newRow.DrillingType?.trim()) validationErrors.push("Drilling Type cannot be empty.");
      if (!newRow.DrillingTypeCode?.trim()) validationErrors.push("Drilling Type Code cannot be empty.");
    }
    if (tabValue === "Sites") {
      if (!newRow.SiteName?.trim()) validationErrors.push("Site Name cannot be empty.");
      if (!newRow.ID_EA_Location) validationErrors.push("Location cannot be empty");
    }
    if (tabValue === "UnitOfMeasurements" && !newRow.Name?.trim()) validationErrors.push("Unit Of Measurement Name cannot be empty.");
    if (tabValue === "Objectives" && !newRow.ObjectiveName?.trim()) validationErrors.push("Objective Name cannot be empty.");
    return validationErrors;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const errors = validateFields(newRow);
    if (errors.length) {
      await AlertService.showAlert(`${renderTabContent()} row has empty required fields.`, 'criticalerror', errors.join("\n"));
      return;
    }
    if (newRow.DateOfBirth) {
      try {
        let InDateFormat = dayjs(newRow.DateOfBirth);
        let convertedToString = InDateFormat.format('MM/DD/YYYY');
        newRow.DateOfBirth = convertedToString;
      }
      catch (e) {
        newRow.DateOfBirth = ""; // maybe show an error message but idk it works.
      }
    }
    const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
    const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
    try {
      const { data } = await Api.post(`/api/Plods/ID_${plodID}/${tabValue}/${endpoint}`, rawData);
      const updatedRow = { ...newRow, ID: data.ID ?? newRow.ID, isNew: false };
      setDataRows(prev => prev.map(row => (row.ID === newRow.ID ? updatedRow : row)));
      await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} ${renderTabContent()} "${newRow[getTabNameField()]}".`, "success");
      return updatedRow;
    } catch (e) {
      handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} ${renderTabContent()} "${newRow[getTabNameField()]}".`);
    }
    return;
  };

  function EditDataToolbar({ setDataRows, setDataRowModesModel }: { setDataRows: React.Dispatch<React.SetStateAction<any[]>>; setDataRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>> }) {
    const handleDataClick = () => {
      const ID = randomId();
      setDataRows((prev) => [
        ...prev,
        {
          ID,
          isNew: true,
          getTabNameField: () => "",
        },
      ]);
      setDataRowModesModel((prev) => ({
        ...prev,
        [ID]: { mode: GridRowModes.Edit, fieldToFocus: getTabNameField() },
      }));
    };
    return (
      <GridToolbarContainer>
        <Button style={{ marginLeft: "auto" }} color="primary" startIcon={<AddIcon />} onClick={handleDataClick}>
          Add {renderTabContent()}
        </Button>
      </GridToolbarContainer>
    );
  }

  //#region Columns
  let singleColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field:
        tabValue == "Consumables"
          ? "ConsumableName"
          : tabValue == "PersonnelTypes"
            ? "PersonnelTypeName"
            : tabValue == "Tasks"
              ? "TaskName"
              : tabValue == "TaskMetrics"
                ? "TaskMetric"
                : tabValue == "Objectives"
                  ? "ObjectiveName"
                  : "LocationName",
      editable: true,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(ID)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(ID)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(ID)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(ID)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  let doubleColumns: GridColDef<any>[] = [
    {
      headerName: "Drilling Type",
      field: "DrillingType",
      editable: true,
      flex: 0.5,
    },
    {
      headerName: "Drilling Type Code",
      field: "DrillingTypeCode",
      editable: true,
      flex: 0.5,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(ID)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(ID)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(ID)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(ID)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  let operatorColumns: GridColDef<any>[] = [
    {
      headerName: "First Name",
      field: "FirstName",
      editable: true,
      flex: 0.3,
    },
    {
      headerName: "Last Name",
      field: "LastName",
      editable: true,
      flex: 0.3,
    },
    {
      headerName: "Date Of Birth",
      field: "DateOfBirth",
      editable: true,
      flex: 0.3,
      type: "date",
      valueFormatter: (params) => dayjs(params.value).format("MM/DD/YYYY"),
      valueGetter: ({ value }) => value && dayjs(value),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(ID)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(ID)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(ID)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(ID)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  let siteColumns: GridColDef<any>[] = [
    {
      headerName: "Site Name",
      field: "SiteName",
      editable: true,
      flex: 0.5,
    },
    {
      headerName: "Location Name",
      field: "ID_EA_Location",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: locationOptions,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(ID)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(ID)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(ID)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(ID)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  let unitOfMeasurementsColumns: GridColDef<any>[] = [
    {
      headerName: "Name",
      field: "Name",
      editable: true,
      flex: 0.5,
    },
    {
      headerName: "Charge By Hours",
      field: "ChargeByHours",
      editable: true,
      flex: 0.5,
      type: 'boolean'
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id: ID }) => {
        const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(ID)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(ID)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(ID)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(ID)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  //#endregion

  //This is for the name thingy
  const renderTabContent = () => {
    switch (tabValue) {
      case 'Personnels':
        return 'FirstName';
      case 'PersonnelTypes':
        return 'PersonnelTypeName';
      case 'Locations':
        return 'LocationName';
      case 'Tasks':
        return 'TaskName';
      case 'TaskMetrics':
        return 'TaskMetric';
      case 'Consumables':
        return 'ConsumableName';
      case 'DrillingTypes':
        return 'DrillingType';
      case 'Sites':
        return 'SiteName';
      case 'UnitOfMeasurements':
        return 'Name';
      case 'Objectives':
        return 'Objective';
      default:
        return 'Error'; // Default fallback if no tab matches
    }
  };
  const getTabNameField = () => {
    switch (tabValue) {
      case 'Personnels':
        return 'Personnel';
      case 'PersonnelTypes':
        return 'Personnel Type';
      case 'Locations':
        return 'Location';
      case 'Tasks':
        return 'Task';
      case 'TaskMetrics':
        return 'Task Metric';
      case 'Consumables':
        return 'Consumable';
      case 'DrillingTypes':
        return 'Drilling Type';
      case 'Sites':
        return 'Site';
      case 'Objectives':
        return 'ObjectiveName';
      default:
        return "BitName";
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTabValue(newTab);
    fetchData(newTab);
  };

  const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setMainTab(newValue);
    switch (newValue) {
      case 0:
        handleTabChange(event, "UnitOfMeasurements");
        break;
      case 1:
        handleTabChange(event, "Personnels");
        break;
    }
  };
  return (
    // Return your main component JSX with data grid, columns, and other elements
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          style={{ marginLeft: 20, marginRight: 20, }}
          variant="fullWidth"
          value={mainTab}
          onChange={handleMainTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="main tabs"
          sx={{
          }}
        >
          <Tab sx={{ maxWidth: 175, height: 60 }} label="Tasks and Consumables" />
          <Tab sx={{ maxWidth: 175, height: 60 }} label="Personnel and Drilling" />
        </Tabs>
        <TabPanel value={mainTab} index={0}>
          <Tabs
            variant="fullWidth"
            value={tabValue}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="Tasks And Consumables"
            style={{ marginLeft: 20 }}
          >
            <Tab sx={{ maxWidth: 175, height: 60 }} value="UnitOfMeasurements" label="Unit Of Measurements" />
            <Tab sx={{ maxWidth: 175, height: 60 }} value="Tasks" label="Tasks" />
            <Tab sx={{ maxWidth: 175, height: 60 }} value="Consumables" label="Consumables" />
            <Tab sx={{ maxWidth: 175, height: 60 }} value="TaskMetrics" label="Task Metric" />
          </Tabs>
        </TabPanel>
        <TabPanel value={mainTab} index={1}>
          <Tabs
            variant="fullWidth"
            value={tabValue}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="Personnel and Drilling"
            style={{ marginLeft: 20 }}
          >
            <Tab sx={{ maxWidth: 175, height: 60 }} value="Personnels" label="Personnels" />
            <Tab sx={{ maxWidth: 175, height: 60 }} value="PersonnelTypes" label="Personnels Type" />
            <Tab sx={{ maxWidth: 175, height: 60 }} value="Sites" label="Sites" />
            <Tab sx={{ maxWidth: 175, height: 60 }} value="Locations" label="Locations" />
            <Tab sx={{ maxWidth: 175, height: 60 }} value="DrillingTypes" label="Drilling Types" />
            <Tab sx={{ maxWidth: 175, height: 60 }} value="Objectives" label="Objectives" />
          </Tabs>
        </TabPanel>
      </Box>
      <Box
        sx={{
          height: "80vh",
          margin: "10px",
          border: 1,
          borderColor: "#DEDEDE",
          borderRadius: 2,
        }}
      >
        <DataGrid
          sx={{ zIndex: 0 }}
          columns={
            tabValue == "Sites"
              ? siteColumns
              : tabValue == "DrillingTypes"
                ? doubleColumns
                : tabValue == "Personnels"
                  ? operatorColumns
                  : tabValue == "UnitOfMeasurements"
                    ? unitOfMeasurementsColumns
                    : singleColumns
          }
          rows={dataRows}
          getRowId={(row) => row.ID}
          editMode="row"
          pagination
          rowModesModel={dataRowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          disableRowSelectionOnClick
          slots={{
            toolbar: EditDataToolbar,
            loadingOverlay: LinearProgress,
          }}
          slotProps={{
            toolbar: {
              setDataRows,
              setDataRowModesModel
            },
          }}
        />
      </Box>
    </>
  );
};

export default Lists;
