import React, { useEffect } from "react";
import {
  Button,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { FilterSpacing } from "./../components/FilterSpacing";
import Api from "../api/api";
import Swal from "sweetalert2";
import { handleErrorResponse } from "../api/apiErrorhandle";
import getPlodID from "../functions/getPlodID";
import getPlodName from "../functions/getPlodName";

const Reports: React.FunctionComponent = () => {
  document.title = "Reports" + " - " + getPlodName();
  let plod_id: string = getPlodID();


  var userPerms = ["Admin"];

  useEffect(() => {
    const shift: any = localStorage.getItem("shiftFilter") || null; // ? WHY IS THIS HERE
    const miniCo: any = localStorage.getItem("miningCompanyFilter") || null; // ? WHY IS THIS HERE
    const contract: any = localStorage.getItem("contractFilter") || null; // ? WHY IS THIS HERE
    const dril: any = localStorage.getItem("drillingTypeFilter") || null; // ? WHY IS THIS HERE
    const rig: any = localStorage.getItem("rigFilter") || null; // ? WHY IS THIS HERE

    setShiftFilter(shift);
    setMiningCompanyFilter(miniCo);
    setContractFilter(contract);
    setDrillingProgramFilter(dril);
    setRigFilter(rig);
  }, []);

  const [startDateValue, setStartDateValue] = React.useState<Dayjs | null>(
    dayjs()
  );
  const [endDateValue, setEndDateValue] = React.useState<Dayjs | null>(dayjs());

  const [tabValue, setTabValue] = React.useState<string>(
    "DetailedDrillingReport"
  );
  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTabValue(newTab);
  };

  const [shiftFilter, setShiftFilter] = React.useState("All");
  const handleShiftFilterChange = (event: SelectChangeEvent) => {
    setShiftFilter(event.target.value as string);
    localStorage.setItem("shiftFilter", event.target.value);
  };

  const [statusFilter, setStatusFilter] = React.useState("All");
  const handleStatusFilterChange = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value as string);
    localStorage.setItem("shiftFilter", event.target.value);
  };

  const [contractFilter, setContractFilter] = React.useState<string>("All");
  const handleContractFilterChange = (event: SelectChangeEvent) => {
    setContractFilter(event.target.value as string);
    localStorage.setItem("contractFilter", event.target.value);
  };

  const [miningCompanyFilter, setMiningCompanyFilter] = React.useState(
    "SelectMiningCompany"
  );
  const handleMiningCompanyFilterChange = (event: SelectChangeEvent) => {
    setMiningCompanyFilter(event.target.value as string);
    localStorage.setItem("miningCompanyFilter", event.target.value);
  };

  const [locationFilter, setLocationFilter] = React.useState<string>("All");
  const handleLocationFilterChange = (event: SelectChangeEvent) => {
    setLocationFilter(event.target.value as string);
  };

  const [rigFilter, setRigFilter] = React.useState<string>("All");
  const handleRigFilterChange = (event: SelectChangeEvent) => {
    setRigFilter(event.target.value as string);
    localStorage.setItem("rigFilter", event.target.value);
  };

  const [siteFilter, setSiteFilter] = React.useState<string>("All");
  const handleSiteFilterChange = (event: SelectChangeEvent) => {
    setSiteFilter(event.target.value as string);
  };

  const [drillingProgramFilter, setDrillingProgramFilter] =
    React.useState<string>("All");
  const handleDrillingProgramFilterChange = (event: SelectChangeEvent) => {
    setDrillingProgramFilter(event.target.value as string);
    localStorage.setItem("drillingTypeFilter", event.target.value);
  };

  const [downloadFilter, setDownloadFilter] = React.useState<string>("Yes");
  const handleDownloadFilterChange = (event: SelectChangeEvent) => {
    setDownloadFilter(event.target.value as string);
  };

  //#region Fetching data for Mining Company Selector.
  const [miningCompanyData, setMiningCompanyData] = React.useState<any>([]);

  React.useEffect(() => {
    const fetchMiningCompanyData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/MiningCompanys`)
        .then(({ data }) => {
          // setMiningCompanyFilter("SelectMiningCompany");
          setMiningCompanyData(data);
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    fetchMiningCompanyData();
  }, []);
  //#endregion

  //#region Fetching data for Contract Selector.
  const [contractData, setContractData] = React.useState<any>([]);
  React.useEffect(() => {
    const fetchContractData = async () => {
      if (
        miningCompanyFilter != "SelectMiningCompany" &&
        miningCompanyFilter !== null
      ) {
        await Api.post(
          `/api/Plods/ID_${plod_id}/MiningCompanys/ID_${miningCompanyFilter}/Contracts`
        )
          .then(({ data }) => {
            if (data.length == 0) {
              setContractFilter("All");
              setContractData([]);
            } else {
              setContractData(data);
            }
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      } else {
        setContractData([]);
        //  setContractFilter("All");
      }
    };
    fetchContractData();
  }, [miningCompanyFilter]);
  //#endregion

  //#region Fetching data for Contract Selector.
  const [rigData, setRigData] = React.useState<any>([]);
  React.useEffect(() => {
    const fetchRigData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/Rigs`)
        .then(({ data }) => {
          if (data.length == 0) {
            setRigFilter("All");
            setRigData([]);
          } else {
            //  setRigFilter("All");
            setRigData(data);
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    fetchRigData();
  }, []);
  //#endregion

  //#region Fetching data for Contract Selector.
  const [drillingProgramData, setDrillingProgramData] = React.useState<any>([]);
  React.useEffect(() => {

    const fetchDrillingTypeData = async () => {
      if (
        contractFilter != "SelectContract" &&
        contractFilter !== null &&
        contractFilter !== "All"
      ) {
        await Api.post(`/api/Plods/ID_${plod_id}/MiningCompanys/ID_${miningCompanyFilter}/Contracts/ID_${contractFilter}/DrillingPrograms`)
          .then(({ data }) => {
            if (data.length == 0) {
              setDrillingProgramFilter("All");
              setDrillingProgramData([]);
            } else {
              // setDrillingTypeFilter("All");
              setDrillingProgramData(data);
            }
          })
          .catch((e) => {
            handleErrorResponse(e);
          });
      }
      else {
        setDrillingProgramData([]);
      }
    };

    fetchDrillingTypeData();
  }, [contractFilter]);
  //#endregion

  //#region Fetching data for Location Selector.
  const [locationData, setLocationData] = React.useState<any>([]);
  React.useEffect(() => {
    const fetchLocationData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/Locations`)
        .then(({ data }) => {
          if (data.length == 0) {
            setLocationData([]);
          } else {
            setLocationData(data);
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    fetchLocationData();
  }, []);
  //#endregion

  //#region Fetching data for Site Selector.
  const [siteData, setSiteData] = React.useState<any>([]);
  React.useEffect(() => {
    const fetchSiteData = async () => {
      await Api.post(`/api/Plods/ID_${plod_id}/Sites`)
        .then(({ data }) => {
          if (data.length == 0) {
            setSiteData([]);
          } else {
            setSiteData(data);
          }
        })
        .catch((e) => {
          handleErrorResponse(e);
        });
    };
    fetchSiteData();
  }, []);
  //#endregion

  const handleDownload = async () => {
    try {

      const dataJSON = {
        PlodID: plod_id,
        MiningCompanyID: miningCompanyFilter == "All" ? -1 : miningCompanyFilter,
        ContractID: contractFilter == "All" ? -1 : contractFilter,
        DrillingProgramID: drillingProgramFilter == "All" ? -1 : drillingProgramFilter,
        Rig: rigFilter == "All" ? -1 : rigFilter,
        Site: siteFilter == "All" ? -1 : siteFilter,
        Shift: shiftFilter,
        Status: statusFilter,
        StartDate: startDateValue,
        EndDate: endDateValue,
      };

      const data: string = JSON.stringify(dataJSON);

      const response = await Api.post(
        `/api/Plods/ID_${plod_id}/Reports/0/~DailyDrillingReport`,
        dataJSON,
        {
          responseType: "blob", // Important to set the response type to blob
        }
      );

      // Create a blob object from the response data
      const blob = new Blob([response.data]);

      // Create a link element
      const downloadLink = document.createElement("a");

      // Set the href attribute of the link to the blob URL
      downloadLink.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      downloadLink.download = "DailyDrillingReport.xlsx";

      // Append the link to the document body
      document.body.appendChild(downloadLink);

      // Trigger the click event to initiate the download
      downloadLink.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <>
      <FormHelperText style={{ marginLeft: 25, marginTop: 25 }}>
        Report Type
      </FormHelperText>
      <hr style={{ marginLeft: 25, marginRight: 25 }} />
      <Box sx={{ width: "100%" }}>
        <Tabs
          style={{ marginLeft: 20, marginRight: 20 }}
          value={tabValue}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {/* <Tab value="ShiftReport" label="Shift Report" /> */}
          {/* <Tab value="TasksActivities" label="Tasks/ Activities" /> */}
          {/* <Tab value="TotalDrilled" label="Total Drilled" /> */}
          {/* <Tab value="CostSummary" label="Cost Summary" /> */}
          {/* <Tab value="PlodRevenueReport" label="Plod Revenue Report (PRR)" /> */}
          <Tab
            value="DetailedDrillingReport"
            label="Detailed Drilling Report (DDR)"
          />
          <Tab value="BitReport" label="Bit Report" />
          <Tab value="WeeklyProjectReport" label="Weekly Project Report" />
        </Tabs>
        {/* <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab value="HoleCostsReport" label="Hole Costs Report" />
          <Tab value="GroutingReport" label="Grouting Report" />
          <Tab value="BitReport" label="Bit Report" />
          <Tab value="SurveyReport" label="Survey Report" />
          <Tab value="MissingShiftReport" label="Missing Shift Report" />
          {userPerms.includes("Admin") || userPerms.includes("Planners") ? (
            <Tab
              value="DetailedDrillingReportPlanners"
              label="Detailed Drilling Report Planners (DDRP)"
            />
          ) : (
            <></>
          )}
          <Tab disabled></Tab>
        </Tabs> */}
      </Box>

      <div className="my-4">
        <FormHelperText style={{ marginLeft: 25 }}>Filters</FormHelperText>
        <hr style={{ marginLeft: 25, marginRight: 25, marginBottom: 25 }} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div
            style={{
              display: "flex",
              marginLeft: 25,
              marginRight: 25,
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            <FilterSpacing>
              <FormHelperText>Mining Company</FormHelperText>
              <Select
                id="miningCompanyFilter"
                value={miningCompanyFilter}
                onChange={handleMiningCompanyFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"SelectMiningCompany"}>
                  Select Mining Company...
                </MenuItem>
                {Array.isArray(miningCompanyData) &&
                  miningCompanyData?.map((data: any): JSX.Element => {
                    return (
                      <MenuItem
                        value={data["ID_EA_MiningCompany"]}
                        key={data["ID_EA_MiningCompany"]}
                      >
                        {data["MiningCompanyName"]}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>Contract #</FormHelperText>
              <Select
                id="contractFilter"
                value={contractFilter}
                onChange={handleContractFilterChange}
                style={{ width: 259 }}
                disabled={miningCompanyFilter == "SelectMiningCompany"}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {contractData.map((data: any): JSX.Element => {
                  return (
                    <MenuItem
                      value={data["ID_EC_Contract"]}
                      key={data["ID_EC_Contract"]}
                    >
                      {data["ContractName"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>Drilling Program</FormHelperText>
              <Select
                id="DrillingProgramFilter"
                value={drillingProgramFilter}
                onChange={handleDrillingProgramFilterChange}
                style={{ width: 259 }}
                disabled={miningCompanyFilter == "SelectMiningCompany" || contractFilter == "SelectContract" || contractFilter == "All"}

              >
                <MenuItem value={"All"}>All</MenuItem>
                {drillingProgramData.map((data: any): JSX.Element => {
                  return (
                    <MenuItem
                      value={data["ID_EDP_Drilling_Program"]}
                      key={data["ID_EDP_Drilling_Program"]}
                    >
                      {data["DrillingProgramName"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FilterSpacing>

            {/*           
  <FilterSpacing>
            <FormHelperText>Site</FormHelperText>
            <Select
              id="siteFilter"
              value={siteFilter}
              onChange={handleSiteFilterChange}
              style={{ width: 259 }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {siteData.map((data: any): JSX.Element => {
                return (
                  <MenuItem
                    value={data["ID_EA_Site"]}
                    key={data["ID_EA_Site"]}
                  >
                    {data["SiteName"]}
                  </MenuItem>
                );
              })}
            </Select>
          </FilterSpacing>  */}

            <FilterSpacing>
              <FormHelperText>Start Date</FormHelperText>
              <DatePicker
                value={startDateValue}
                maxDate={endDateValue}
                onChange={(newValue) => {
                  setStartDateValue(newValue);
                  localStorage.setItem("startDate", JSON.stringify(newValue));
                }}
                format="DD/MM/YYYY"
              />
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>End Date</FormHelperText>
              <DatePicker
                value={endDateValue}
                minDate={startDateValue}
                maxDate={dayjs()}
                onChange={(newValue) => {
                  setEndDateValue(newValue);
                  localStorage.setItem("endDate", JSON.stringify(newValue));
                }}
                format="DD/MM/YYYY"
              />
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>Rig</FormHelperText>
              <Select
                id="rigFilter"
                value={rigFilter}
                onChange={handleRigFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {rigData.map((data: any): JSX.Element => {
                  return (
                    <MenuItem value={data["ID_EA_Rig"]} key={data["ID_EA_Rig"]}>
                      {data["RigName"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>Site</FormHelperText>
              <Select
                id="siteFilter"
                value={siteFilter}
                onChange={handleSiteFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {siteData.map((data: any): JSX.Element => {
                  return (
                    <MenuItem value={data["ID_EA_Site"]} key={data["ID_EA_Site"]}>
                      {data["SiteName"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>Shift</FormHelperText>
              <Select
                id="shiftFilter"
                value={shiftFilter}
                onChange={handleShiftFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"All"} key={"All"}>
                  All
                </MenuItem>
                <MenuItem value={"Day"} key={"Day"}>
                  Day
                </MenuItem>
                <MenuItem value={"Night"} key={"Night"}>
                  Night
                </MenuItem>
              </Select>
            </FilterSpacing>

            <FilterSpacing>
              <FormHelperText>Status</FormHelperText>
              <Select
                id="statusFilter"
                value={statusFilter}
                onChange={handleStatusFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"All"} key={"All"}>
                  All
                </MenuItem>
                <MenuItem value={"NONE"} key={"NONE"}>
                  NONE
                </MenuItem>
                <MenuItem
                  value={"WAITINGFORSUPERVISOR"}
                  key={"WAITINGFORSUPERVISOR"}
                >
                  WAITING FOR SUPERVISOR
                </MenuItem>
                <MenuItem value={"WAITINGFORCLIENT"} key={"WAITINGFORCLIENT"}>
                  WAITING FOR CLIENT
                </MenuItem>
                <MenuItem value={"APPROVED"} key={"APPROVED"}>
                  APPROVED
                </MenuItem>
              </Select>
            </FilterSpacing>

            {/* <FilterSpacing>
              <FormHelperText>Location</FormHelperText>
              <Select
                id="locationFilter"
                value={locationFilter}
                onChange={handleLocationFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"All"}>All</MenuItem>
                {locationData.map((data: any): JSX.Element => {
                  return (
                    <MenuItem
                      value={data["ID_EA_Location"]}
                      key={data["ID_EA_Location"]}
                    >
                      {data["LocationName"]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FilterSpacing> */}

            {/* <FilterSpacing>
              <FormHelperText>Download</FormHelperText>
              <Select
                id="downloadFilter"
                value={downloadFilter}
                onChange={handleDownloadFilterChange}
                style={{ width: 259 }}
              >
                <MenuItem value={"Yes"} key={"Yes"}>
                  Yes
                </MenuItem>
                <MenuItem value={"No"} key={"No"}>
                  No
                </MenuItem>
              </Select>
            </FilterSpacing> */}
          </div>
        </LocalizationProvider>
      </div>

      <FormHelperText style={{ marginLeft: 25, marginTop: 5 }}>
        Download
      </FormHelperText>
      <hr
        style={{
          marginLeft: 25,
          marginRight: 25,
          marginBottom: 25,
          marginTop: 5,
        }}
      />
      <div
        style={{
          display: "flex",
          marginLeft: 25,
          marginRight: 25,
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        <Button variant="contained" onClick={() => handleDownload()}>
          {" "}
          Download DDR REPORT
        </Button>
      </div>
    </>
  );
};

export default Reports;
