import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { useState } from "react";
import { SidebarData } from "./SidebarData";
import styled from "styled-components";
import Api from "../api/api";
import { useSelector } from "react-redux";
import type { RootState } from "../app/store";
import getPlodID from '../functions/getPlodID';
import getPlodName from '../functions/getPlodName';
import secureLocalStorage from "react-secure-storage";
import { CircularProgress } from "@mui/material";



// Styled components
const SidebarMenu = styled.div`
  width: 250px;
  height: 100vh;
  background-color: #000080;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.6s;
`;

const MenuItems = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 60px;
  padding: 1rem 0 1.25rem;
  user-select: none;
`;

const MenuItemLinks = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 20px;
  text-decoration: none;
  color: #ffffff;

  &:hover {
    background-color: #ffffff;
    color: #000080;
    width: 100%;
    height: 45px;
    text-align: center;
    border-radius: 5px;
  }
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Sidebar: React.FunctionComponent = () => {
  const plod_id = secureLocalStorage.getItem("plod_id");
  const plodId = useSelector((state: RootState) => state.plodData.plod_id);
  const plod = plodId || plod_id;

  const PlodName = getPlodName();

  const [access, setAccess] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Track loading state

  const getAccess = async () => {
    try {
      setLoading(true); // Start loading
      const { data, status } = await Api.post(
        `api/Plods/ID_${plod}/LoggedInTeamMember/0/~MenuItemsAvailable`
      );

      if (status === 200) {
        setAccess(data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    if (plod) {
      getAccess();
    }
  }, [plod]);

  const hasRoles = access?.length > 0;

  const hasAccess = (path: string) => {
    if (!hasRoles) return false;
    const isAccess = access.find(
      (i: any) =>
        i.Menu.toLowerCase() === path.toLowerCase() &&
        (i.Active === "True" || i.Active === true)
    );
    return !!isAccess;
  };

  return (
    <>
      {/* If loading, show the spinner */}

      <SidebarMenu>
        <React.Fragment key={"DrillDownName"}>
          <h1
            style={{
              color: "white",
              paddingTop: 10,
              textAlign: "center",
            }}
          >
            DRILL DOWN
          </h1>
          <h3 style={{ color: "white", paddingTop: 10, paddingBottom: 10, marginLeft: 25 }}>
            {PlodName}
          </h3>
        </React.Fragment>
        {/* Home can always be seen */}
        <MenuItems>
          <MenuItemLinks to="/">
            <FaIcons.FaHome />
            <span style={{ marginLeft: "16px" }}>Home</span>
          </MenuItemLinks>
        </MenuItems>
        <React.Fragment key={"HomeSpacer"}>
          <hr style={{ marginLeft: "16px", marginRight: "16px" }} />
        </React.Fragment>
        {loading ? (
          <LoadingSpinner>
            <CircularProgress />
          </LoadingSpinner>
        ) :
          SidebarData.map((item, index) => {
            if (item.title === "Spacer") {
              return (
                <React.Fragment key={index}>
                  {hasRoles && (
                    <hr style={{ marginLeft: "16px", marginRight: "16px" }} />
                  )}
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={index}>
                {!hasRoles && item.path === "/" ? (
                  <MenuItems>
                    <MenuItemLinks to={item.path}>
                      {item.icon}
                      <span style={{ marginLeft: "16px" }}>{item.title}</span>
                    </MenuItemLinks>
                  </MenuItems>
                ) : hasAccess(item.title) ? (
                  <MenuItems>
                    <MenuItemLinks to={item.path}>
                      {item.icon}
                      <span style={{ marginLeft: "16px" }}>
                        {item.title === "Teams" ? "Team" : item.title}
                      </span>
                    </MenuItemLinks>
                  </MenuItems>
                ) : null}
              </React.Fragment>
            );
          })}
      </SidebarMenu>

    </>
  );
};

export default Sidebar;
