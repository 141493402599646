// useEditableRowHandlers.ts
import { useState } from 'react';
import { GridRowId, GridRowModes, GridRowModel } from '@mui/x-data-grid';

type RowModel = {
    ID: GridRowId;
    isNew?: boolean;
    // Add other row properties here if needed
};

type RowModesState = Record<GridRowId, { mode: GridRowModes; ignoreModifications?: boolean }>;

type GenericHandlersProps<T extends RowModel> = {
    rowModesModel: RowModesState;
    setRowModesModel: React.Dispatch<React.SetStateAction<RowModesState>>;
    rows: T[];
    setRows: React.Dispatch<React.SetStateAction<T[]>>;
};

export function useEditableRowHandlers<T extends RowModel>({
    rowModesModel,
    setRowModesModel,
    rows,
    setRows,
}: GenericHandlersProps<T>) {
    const handleEditClick = (ID: GridRowId) => () => {
        setRowModesModel((prev) => ({
            ...prev,
            [ID]: { mode: GridRowModes.Edit },
        }));
    };

    const handleSaveClick = (ID: GridRowId) => () => {
        setRowModesModel((prev) => ({
            ...prev,
            [ID]: { mode: GridRowModes.View },
        }));
    };

    const handleDeleteClick = (ID: GridRowId) => () => {
        setRows(rows.filter((row) => row.ID !== ID));
    };

    const handleCancelClick = (ID: GridRowId) => () => {
        setRowModesModel((prev) => ({
            ...prev,
            [ID]: { mode: GridRowModes.View, ignoreModifications: true },
        }));

        const editedRow = rows.find((row) => row.ID === ID);
        if (editedRow?.isNew) {
            setRows(rows.filter((row) => row.ID !== ID));
        }
    };

    return {
        handleEditClick,
        handleSaveClick,
        handleDeleteClick,
        handleCancelClick,
    };
}
