import React, { useEffect, useState } from 'react';
import "./../styles.css";
import { Button } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Api from '../api/api';
import { handleErrorResponse } from '../api/handleErrorResponse';
import getPlodID from '../functions/getPlodID';
import getPlodName from '../functions/getPlodName';
import useStandardEditableRowActions from '../components/useStandardEditableRowActions';
import { AlertService } from '../services/AlertService';
import CustomNoRowsOverlay from '../components/CustomNoRowsOverlay';

const Clients: React.FunctionComponent = () => {
    document.title = "Clients" + " - " + getPlodName();

    const [dataGridError, setDataGridError] = useState<string | null>(null);
    const plodID = getPlodID();
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [dataRowModesModel, setDataRowModesModel] = useState<GridRowModesModel>({});

    const {
        handleSaveClick,
        handleCancelClick,
        handleEditClick,
        handleRowModesModelChange,
    } = useStandardEditableRowActions({
        dataRows: dataRows,
        dataRowModesModel: dataRowModesModel,
        setDataRowModesModel: setDataRowModesModel,
        setDataRows: setDataRows,
    });

    const fetchData = async () => {
        setDataRows([]);
        setDataGridError(null);
        setLoading(true);
        try {
            const { data } = await Api.post(`/api/Plods/ID_${plodID}/MiningCompanys`);
            setDataRows(data);
        } catch (e) {
            setDataRows([]);
            setDataGridError("An error occured while loading Clients, Please try again later.");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (plodID) fetchData();
    }, [plodID]);

    const handleDeleteClick = (ID: GridRowId) => async () => {
        const currentMiningCompanyName: string = await dataRows.filter((row) => row.ID === ID)[0].MiningCompanyName;
        const confirmed = await AlertService.showAlert(`Are you sure you want to delete the Client "${currentMiningCompanyName}".`, "question");
        if (confirmed) {
            try {
                const { data } = await Api.post(
                    `/api/Plods/ID_${plodID}/MiningCompanys/~DeleteItem`,
                    JSON.stringify({ ID })
                );
                setDataRows((prev) => prev.filter((row) => row.ID !== data.ID));
                await AlertService.showAlert(`Successfully Deleted Client "${currentMiningCompanyName}".`, "success");
            } catch (e: unknown) {
                handleErrorResponse(e, `Error Deleting Client "${currentMiningCompanyName}".`);
            }
        }
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        if (!newRow.MiningCompanyName?.trim()) {
            await AlertService.showAlert("Client Name cannot be empty", 'criticalerror');
        }
        else {
            try {
                const rawData = JSON.stringify({ ID: newRow.ID, ...newRow });
                const endpoint = newRow.isNew ? "~AddItem" : "~UpdateItem";
                const { data } = await Api.post(
                    `/api/Plods/ID_${plodID}/MiningCompanys/${endpoint}`,
                    rawData
                );
                const updatedRow = {
                    ...newRow,
                    ID: data.ID ?? newRow.ID,
                    isNew: false,
                };
                setDataRows((prev) =>
                    prev.map((row) => (row.ID === newRow.ID ? updatedRow : row))
                );
                await AlertService.showAlert(`Successfully ${newRow.isNew ? "Added" : "Updated"} Client "${newRow.MiningCompanyName}".`, "success");
                return updatedRow;
            } catch (e) {
                handleErrorResponse(e, `Error ${newRow.isNew ? "Adding" : "Updating"} Client "${newRow.MiningCompanyName}".`);
            }
        }
        return newRow;
    };

    interface EditToolbarProps {
        setDataRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
        setDataRowModesModel: (
            newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
        ) => void;
    }
    function EditToolbar(props: EditToolbarProps) {
        const { setDataRows, setDataRowModesModel } = props;

        const handleClick = () => {
            const ID = randomId();
            setDataRows((oldRows: any) => [...oldRows, { ID, MiningCompanyName: '', MiningCompanyCode: '', isNew: true }]);
            setDataRowModesModel((oldModel: any) => ({
                ...oldModel,
                [ID]: { mode: GridRowModes.Edit, fieldToFocus: 'MiningCompanyName' },
            }));
        };

        return (
            <GridToolbarContainer>
                <Button style={{ marginLeft: "auto", marginRight: 0 }} color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Add Client
                </Button>
            </GridToolbarContainer>
        );
    };

    return (
        <>
            <Box sx={{ height: "90vh", margin: "10px", border: 1, borderColor: '#DEDEDE', borderRadius: 2 }}>
                <DataGrid sx={{ zIndex: 0 }}
                    columns={[
                        {
                            field: 'MiningCompanyName',
                            headerName: "Client Name",
                            editable: true,
                            flex: 1
                        },
                        {
                            field: 'MiningCompanyCode',
                            headerName: "Client Code",
                            editable: true,
                            flex: 1
                        },
                        {
                            field: 'actions',
                            type: 'actions',
                            headerName: 'Actions',
                            width: 100,
                            cellClassName: 'actions',
                            getActions: ({ id: ID }) => {
                                const isInEditMode = dataRowModesModel[ID]?.mode === GridRowModes.Edit;
                                return isInEditMode ? [
                                    <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(ID)} />,
                                    <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(ID)} color="inherit" />,
                                ] : [
                                    <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(ID)} color="inherit" />,
                                    <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(ID)} color="inherit" />,
                                ];
                            }
                        }
                    ]}
                    rows={dataRows}
                    rowModesModel={dataRowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    processRowUpdate={processRowUpdate}
                    editMode="row"
                    disableRowSelectionOnClick
                    loading={loading}
                    getRowId={(row) => row.ID} // Gets the id from database, not the local style one
                    slots={{
                        toolbar: EditToolbar,
                        noRowsOverlay: () => (
                            <CustomNoRowsOverlay
                                message={dataGridError ? dataGridError : "No Client Data"}
                                onRetry={dataGridError ? fetchData : undefined}
                            />
                        ),
                    }}
                    slotProps={{
                        toolbar: {
                            setDataRows,
                            setDataRowModesModel
                        },
                    }}
                />
            </Box>

        </>
    );
};

export default Clients;
