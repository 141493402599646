import secureLocalStorage from "react-secure-storage";

function getPlodID(): string {
    let plod_id = secureLocalStorage.getItem("plod_id"); // can be any of these: string | number | boolean | object | null
    if (plod_id == null || plod_id == "") {
        window.location.replace(
            //?Should this have some functionality on the server ?? 
            "https://login.redochre.cloud/?reason=FailedToGetInstance" // TODO: Look more into this 
        );
        return '';
    }
    return plod_id.toString();
}

export default getPlodID;

