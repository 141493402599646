import React from 'react';

import { Button } from '@mui/material';
import { DataGrid, GridColDef, GridRowModel, GridRowModes, GridRowModesModel, GridToolbarContainer } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';
import AddIcon from "@mui/icons-material/Add";

interface CustomDataGridProps {
    columns: GridColDef[];
    rows: any[];
    rowModesModel: any;
    setRows: React.Dispatch<React.SetStateAction<any[]>>;
    setRowModesModel: React.Dispatch<React.SetStateAction<any>>;
    fieldToFocus: string,
    buttonText: string,
    initialRow: any,
    shiftID: string;
    drillingProgramID: string;
}

interface GenericToolbarProps<T> {
    setRows: React.Dispatch<React.SetStateAction<T[]>>;
    setRowModesModel: React.Dispatch<React.SetStateAction<any>>;
    initialRow: T;
    fieldToFocus: keyof T;
    buttonText: string;
    disabled: boolean;
}

const GenericToolbar = <T,>({
    setRows,
    setRowModesModel,
    initialRow,
    fieldToFocus,
    buttonText,
    disabled,
}: GenericToolbarProps<T>) => {
    const handleAddClick = () => {
        const ID = randomId(); // Ensure randomId() is defined or imported
        setRows((oldRows) => [
            ...oldRows,
            { ...initialRow, ID, isNew: true },
        ]);
        setRowModesModel((oldModel: T) => ({
            ...oldModel,
            [ID]: { mode: GridRowModes.Edit, fieldToFocus },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button
                style={{ marginLeft: 'auto', marginRight: 0 }}
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddClick}
                disabled={disabled}
            >
                {buttonText}
            </Button>
        </GridToolbarContainer>
    );
};

const CustomPlodSheetDataGridProps = ({
    columns,
    rows,
    rowModesModel,
    setRows,
    setRowModesModel,
    fieldToFocus,
    buttonText,
    initialRow,
    shiftID,
    drillingProgramID,
}: CustomDataGridProps) => {
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
        // console.log('Row modes model updated:', newRowModesModel);
    };
    const processRowUpdate = (newRow: GridRowModel) => {
        var updatedRow = { ...newRow, isNew: false };
        setRows(
            rows.map((row: any) =>
                row.ID === newRow.ID ? updatedRow : row
            )
        );
        // console.log('Row Updated:', newRow);
        return updatedRow;
    };

    return (
        <DataGrid
            columns={columns}
            rows={rows}
            editMode="row"
            getRowId={(row) => row.ID}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            processRowUpdate={processRowUpdate}
            disableRowSelectionOnClick
            slots={{
                toolbar: GenericToolbar,
            }}
            slotProps={{
                toolbar: {
                    setRows,
                    setRowModesModel,
                    initialRow,
                    fieldToFocus,
                    buttonText,
                    disabled: false,
                },
            }}
            sx={{ minHeight: 300 }}
        />
    );
};

export default CustomPlodSheetDataGridProps;

