import React from 'react';
import { useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Api from '../api/api';
import { useDispatch } from "react-redux";
import { stateData } from "../feature/plodSlice";

interface ProtectedRouteProps {
    children: React.ReactNode;
}
// Utility function to get the token from the URL and remove it
const getTokenFromUrlAndClean = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('LoginToken');
    if (token) {
        // Remove the token from the URL
        urlParams.delete('LoginToken');
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
    }
    return token;
};

const validateTokenWithAPI = async (): Promise<boolean> => {
    const dispatch = useDispatch();
    try {
        const { data } = await Api.post(`/api/Plods/~Start`);
        console.log("API call successful");
        // Dispatch state and save data in local storage
        if (secureLocalStorage.getItem("plod_id")?.toString() != data.ID.toString() || secureLocalStorage.getItem("PlodName")?.toString() != data.Plod_App_DrillingCompanyName) {
            secureLocalStorage.setItem("plod_id", data.ID);
            secureLocalStorage.setItem("PlodName", data.Plod_App_DrillingCompanyName);
            dispatch(stateData(data));
        }
        return true;
    } catch (error) {
        console.error("Error validating token:", error);
        return false;
    }
};

//Need to look into stopping it doing it twice.
const WithAuth: React.FC<ProtectedRouteProps> = ({ children }) => {
    const urlToken = getTokenFromUrlAndClean();
    const localToken = secureLocalStorage.getItem('loginToken');
    const token = urlToken || localToken;

    // Store the token from URL in local storage
    if (urlToken) {
        secureLocalStorage.clear(); // Remove all old data
        secureLocalStorage.setItem('loginToken', urlToken);
    }

    const handleTokenValidation = async () => {
        const isValid = await validateTokenWithAPI();
        if (!isValid) {
            secureLocalStorage.clear(); // Remove token on failure
            window.location.replace("https://login.redochre.cloud/?reason=failedwithtokenvalidation");
        }
    };

    if (urlToken) {
        handleTokenValidation();
    } else if (!token) {
        // If no token, redirect to the external login page
        window.location.href = 'https://login.redochre.cloud/?reason=nologintoken';
        return null;
    } else {
        handleTokenValidation(); // this should potentially be a different one.
    }

    return <>{children}</>;
};
export default WithAuth;